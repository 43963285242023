import { ProjectActivity } from '@/entity/project'

import { CreateWorkLogBase } from './create-work-log-base'

export interface CreateWorkLogWithTypicalActivity extends CreateWorkLogBase {
  typicalWork: ProjectActivity
}

export const isWorkLogFormWithTypicalActivity = (payload: unknown): payload is CreateWorkLogWithTypicalActivity =>
  (payload as CreateWorkLogBase).workType === 'TYPICAL'
