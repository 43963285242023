import { ReactElement, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Spinner } from '@chakra-ui/react'

import { useGoogleAuth } from '@/features/auth'
import { GOOGLE_TOKEN_STORAGE_KEY } from '@/shared/constants'

export const AuthRedirectPage = (): ReactElement | null => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const googleAuth = useGoogleAuth()

  useEffect(() => {
    const code = searchParams.get('code')
    if (!code) {
      navigate('/')
      return
    }

    if (!googleAuth.isPending) {
      googleAuth.mutate(code, {
        onSuccess: (response) => {
          localStorage.setItem(GOOGLE_TOKEN_STORAGE_KEY, response.token)
          navigate('/dashboard')
        },
        onError: () => {
          navigate('/')
        },
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box h="100vh" w="100%" display="flex" alignItems="center" justifyContent="center">
      <Spinner />
    </Box>
  )
}
