import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { CreateProjectRoleForm } from '@/features/project'

export const useUpdateProjectRole = (roleId?: string) =>
  useMutation({
    mutationFn: (payload: CreateProjectRoleForm) =>
      client.put(`/project-roles/${roleId}`, payload).then(({ data }) => data),
  })
