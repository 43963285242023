import yup from '@/shared/config/yup.config'
import { UserRole } from '@/entity/user'

export const validationSchema = yup.object({
  email: yup.string().required(),
  role: yup.mixed<UserRole>().oneOf(Object.values(UserRole)).required(),
  department: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  workLogRequired: yup.boolean().required(),
  defaultProjectRole: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable(),
})
