import { GroupBase } from 'chakra-react-select'

import { SelectProps } from '../model/select-props'
import { OptionKeyMapper } from '../model/option-key-mapper'

const OPTION_EMPTY_STRUCTURE: OptionKeyMapper = {
  valueKey: '',
  labelKey: '',
}

const isOptionGroup = <Option>(option: unknown): option is GroupBase<Option> => !!(option as GroupBase<Option>).options

const getOptionKey = <Option>(option: NonNullable<Option>, value?: string): string | undefined =>
  Object.keys(option).find((key) => option[key as keyof Option] === value)

export const getOptionKeys = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: SelectProps<Option, IsMulti, Group>,
): OptionKeyMapper => {
  if (!props.options?.length) {
    return OPTION_EMPTY_STRUCTURE
  }

  const optionOrGroup = props.options?.[0]
  if (!optionOrGroup || isOptionGroup(optionOrGroup)) {
    return OPTION_EMPTY_STRUCTURE
  }

  const optionValue = props.getOptionValue?.(optionOrGroup)
  const optionLabel = props.getOptionLabel?.(optionOrGroup)

  return {
    valueKey: getOptionKey(optionOrGroup, optionValue) ?? '',
    labelKey: getOptionKey(optionOrGroup, optionLabel) ?? '',
  }
}
