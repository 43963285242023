import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { CreateDepartmentForm } from '../../model/create-department-form'

export const useUpdateDepartment = (departmentId?: string) =>
  useMutation({
    mutationFn: (payload: CreateDepartmentForm) =>
      client.put(`/departments/${departmentId}`, payload).then(({ data }) => data),
  })
