import { ReactElement, useEffect, useId, useMemo, useRef, useState } from 'react'
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Skeleton,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import { format, startOfMonth } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  CreateDailyReportForm,
  CreateProjectReportForm,
  CreateReportForm,
  DailyReportPreviewTable,
  isCreateDailyReportForm,
  isDailyReport,
  isProjectReport,
  ProjectAggregatedPreviewTable,
  ProjectDetailedPreviewTable,
  useCreateDailyReport,
  useCreatePreviewDailyReport,
  useCreatePreviewProjectReport,
  useCreateProjectReport,
  useReportTypeList,
} from '@/features/report'
import { useAvailableProjectList } from '@/features/project'
import { useFilteredUserList } from '@/features/user'
import { useDepartmentList } from '@/features/department'
import { debounce, downloadBlobFile, isDeepEqual } from '@/shared/utils'
import {
  DailyReportPreview,
  isProjectAggregatedReportPreview,
  isProjectDetailedReportPreview,
  ProjectAggregatedReportPreview,
  ProjectDetailedReportPreview,
  ReportType,
} from '@/entity/report'
import { Select } from '@/shared/ui-kit'

import { createProjectReportSchema } from '../lib/validation'

const REPORT_FILE_TYPE = 'xlsx'
const FORM_VALUES_DEBOUNCE_TIME = 2_000

export const ReportsPage = (): ReactElement => {
  const { getValues, register, watch, control, handleSubmit, formState } = useForm<CreateReportForm>({
    defaultValues: {
      reportType: null,
      dateFrom: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      dateTo: format(new Date(), 'yyyy-MM-dd'),
      project: null,
      projects: null,
      users: null,
      departments: null,
    },
    resolver: yupResolver<CreateReportForm>(createProjectReportSchema),
  })

  const reportTypeValue = watch('reportType')
  const dateFromValue = watch('dateFrom')
  const dateToValue = watch('dateTo')
  const formValues = getValues()

  const previousFormValues = useRef<CreateReportForm>()

  const [inputValue, setInputValue] = useState<string>('')
  const [dailyReportPreview, setReportPreviewTasks] = useState<DailyReportPreview | null>(null)
  const [projectDetailedPreview, setProjectDetailedPreview] = useState<ProjectDetailedReportPreview | null>(null)
  const [projectAggregatedPreview, setProjectAggregatedPreview] = useState<ProjectAggregatedReportPreview | null>(null)

  const reportTypeList = useReportTypeList()
  const availableProjectList = useAvailableProjectList()
  const departmentList = useDepartmentList()
  const filteredUserList = useFilteredUserList({
    name: inputValue,
    workLogRequired: true,
  })

  const createDailyReport = useCreateDailyReport()
  const createProjectReport = useCreateProjectReport()
  const createDailyReportPreview = useCreatePreviewDailyReport()
  const createProjectReportPreview = useCreatePreviewProjectReport()

  const reportTypeId = useId()
  const projectId = useId()
  const dateFromId = useId()
  const dateToId = useId()
  const filteredUserId = useId()
  const departmentId = useId()

  const createDailyReportFormErrors = formState.errors as FieldErrors<CreateDailyReportForm>
  const createProjectReportFormErrors = formState.errors as FieldErrors<CreateProjectReportForm>

  const isDailyReportForm = isDailyReport(reportTypeValue)
  const isProjectReportForm = isProjectReport(reportTypeValue)
  const isPreviewLoading = createDailyReportPreview.isPending || createProjectReportPreview.isPending || false

  const handleInputChange = (inputText: string) => {
    setInputValue(inputText)
  }

  const clearPreview = (): void => {
    setReportPreviewTasks(null)
    setProjectDetailedPreview(null)
    setProjectAggregatedPreview(null)
  }

  const handleCreateReportPreview = (values: CreateReportForm): void => {
    if (isDeepEqual(values, previousFormValues.current)) {
      return
    }

    previousFormValues.current = values
    clearPreview()

    if (isCreateDailyReportForm(values)) {
      createDailyReportPreview.mutate(values, {
        onSuccess: (response) => {
          setReportPreviewTasks(response)
        },
      })
    } else {
      createProjectReportPreview.mutate(values, {
        onSuccess: (response) => {
          if (isProjectDetailedReportPreview(response)) {
            setProjectDetailedPreview(response)
          }

          if (isProjectAggregatedReportPreview(response)) {
            setProjectAggregatedPreview(response)
          }
        },
      })
    }
  }

  const handleFormSubmit = (values: CreateReportForm): void => {
    if (isCreateDailyReportForm(values)) {
      createDailyReport.mutate(values, {
        onSuccess: (blobParts) => {
          downloadBlobFile({
            blobParts,
            name: `Дневной отчет | ${values.dateFrom} - ${values.dateTo}`,
            extension: REPORT_FILE_TYPE,
          })
        },
      })
    } else {
      createProjectReport.mutate(values, {
        onSuccess: (blobParts) => {
          downloadBlobFile({
            blobParts,
            name: `Проектный отчет - ${values.project?.name} | ${values.dateFrom} - ${values.dateTo}`,
            extension: REPORT_FILE_TYPE,
          })
        },
      })
    }
  }

  const debouncedCreateReportPreview = useMemo(
    () => debounce((values: CreateReportForm) => handleCreateReportPreview(values), FORM_VALUES_DEBOUNCE_TIME),
    // eslint-disable-next-line
    [],
  )

  const renderContent = (): ReactElement | null => {
    if (isPreviewLoading) {
      return (
        <Center flexDir="column" rowGap={4} height="100%" width="100%">
          <Spinner size="xl" />
          <Text>Загрузка превью отчета...</Text>
        </Center>
      )
    }

    const reportComponents: Record<ReportType['code'], ReactElement | null> = {
      PROJECT_AGGREGATE: <ProjectAggregatedPreviewTable data={projectAggregatedPreview} />,
      PROJECT_DETAILED: <ProjectDetailedPreviewTable data={projectDetailedPreview} />,
      DAILY: <DailyReportPreviewTable data={dailyReportPreview} />,
    }

    return reportTypeValue?.code ? reportComponents[reportTypeValue.code] : null
  }

  useEffect(() => {
    debouncedCreateReportPreview(formValues)
  }, [formValues, debouncedCreateReportPreview])

  return (
    <Flex flexDir="column" height="100%">
      <Heading>Генерация отчета</Heading>

      <Flex mt={8} columnGap={8} height="100%">
        <Flex flexDir="column" as="form" rowGap={4} width="300px" onSubmit={handleSubmit(handleFormSubmit)}>
          <FormControl isRequired isInvalid={!!formState.errors.reportType?.message}>
            <Skeleton isLoaded={!reportTypeList.isLoading}>
              <FormLabel htmlFor={reportTypeId}>Тип справочника</FormLabel>
            </Skeleton>

            <Skeleton isLoaded={!reportTypeList.isLoading}>
              <Controller
                control={control}
                name="reportType"
                render={({ field }) => (
                  <Select
                    {...field}
                    inputId={reportTypeId}
                    isClearable
                    placeholder="Выберите департамент..."
                    getOptionValue={(option) => option.code}
                    getOptionLabel={(option) => option.name}
                    isLoading={reportTypeList.isLoading}
                    options={reportTypeList.data}
                  />
                )}
              />
            </Skeleton>

            <FormErrorMessage>{formState.errors.reportType?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!formState.errors.dateFrom?.message}>
            <FormLabel htmlFor={dateFromId}>Дата от</FormLabel>

            <Input id={dateFromId} type="date" max={dateToValue} {...register('dateFrom')} />

            <FormErrorMessage>{formState.errors.dateFrom?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!formState.errors.dateTo?.message}>
            <FormLabel htmlFor={dateToId}>Дата до</FormLabel>

            <Input
              id={dateToId}
              type="date"
              min={dateFromValue}
              max={format(new Date(), 'yyyy-MM-dd')}
              {...register('dateTo')}
            />

            <FormErrorMessage>{formState.errors.dateTo?.message}</FormErrorMessage>
          </FormControl>

          {isProjectReportForm && (
            <FormControl isRequired isInvalid={!!createProjectReportFormErrors.project?.message}>
              <Skeleton isLoaded={!availableProjectList.isLoading}>
                <FormLabel htmlFor={projectId}>Проект</FormLabel>
              </Skeleton>

              <Skeleton isLoaded={!availableProjectList.isLoading}>
                <Controller
                  control={control}
                  name="project"
                  render={({ field }) => (
                    <Select
                      {...field}
                      inputId={projectId}
                      isClearable
                      placeholder="Выберите проект..."
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isLoading={availableProjectList.isLoading}
                      options={availableProjectList.data}
                    />
                  )}
                />
              </Skeleton>

              <FormErrorMessage>{createProjectReportFormErrors.project?.message}</FormErrorMessage>
            </FormControl>
          )}

          {isDailyReportForm && (
            <>
              <FormControl isRequired isInvalid={!!createDailyReportFormErrors.projects?.message}>
                <Skeleton isLoaded={!availableProjectList.isLoading}>
                  <FormLabel htmlFor={projectId}>Проекты</FormLabel>
                </Skeleton>

                <Skeleton isLoaded={!availableProjectList.isLoading}>
                  <Controller
                    control={control}
                    name="projects"
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputId={projectId}
                        isMulti
                        isAllSelect
                        isClearable
                        placeholder="Выберите проект..."
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        isLoading={availableProjectList.isLoading}
                        options={availableProjectList.data}
                      />
                    )}
                  />
                </Skeleton>

                <FormErrorMessage>{createDailyReportFormErrors.projects?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!createDailyReportFormErrors.users?.message}>
                <FormLabel htmlFor={filteredUserId}>Пользователи</FormLabel>

                <Controller
                  control={control}
                  name="users"
                  render={({ field }) => (
                    <Select
                      {...field}
                      inputId={filteredUserId}
                      isMulti
                      isClearable
                      filterOption={null}
                      placeholder="Введите имя сотрудника..."
                      noOptionsText="Данный сотрудник отсутствует"
                      loadingMessage={() => 'Загрузка...'}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.displayName}
                      inputValue={inputValue}
                      isLoading={filteredUserList.isLoading}
                      options={filteredUserList.data}
                      onInputChange={handleInputChange}
                    />
                  )}
                />

                <FormErrorMessage>{createDailyReportFormErrors.users?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!createDailyReportFormErrors.departments?.message}>
                <Skeleton isLoaded={!departmentList.isLoading}>
                  <FormLabel htmlFor={departmentId}>Департаменты</FormLabel>
                </Skeleton>

                <Skeleton isLoaded={!departmentList.isLoading}>
                  <Controller
                    control={control}
                    name="departments"
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputId={departmentId}
                        isMulti
                        isClearable
                        placeholder="Выберите департамент..."
                        noOptionsText="Данный департамент отсутствует"
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        isLoading={departmentList.isLoading}
                        options={departmentList.data}
                      />
                    )}
                  />
                </Skeleton>

                <FormErrorMessage>{createDailyReportFormErrors.departments?.message}</FormErrorMessage>
              </FormControl>
            </>
          )}

          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={formState.isSubmitting || !formState.isDirty || !formState.isValid}
            isLoading={createProjectReport.isPending || createDailyReport.isPending}
          >
            Скачать отчет
          </Button>
        </Flex>

        <Flex position="relative" width="calc(100% - 300px)">
          {renderContent()}
        </Flex>
      </Flex>
    </Flex>
  )
}
