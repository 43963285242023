import yup from '@/shared/config/yup.config'
import { ProjectType } from '@/entity/project'

export const validationSchema = yup.object({
  name: yup.string().required(),
  type: yup.mixed<ProjectType>().oneOf(['COMMERCIAL', 'NONCOMMERCIAL', 'INVEST']).required(),
  department: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable(),
  managers: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        displayName: yup.string().required(),
      }),
    )
    .required()
    .min(1, 'Выберите как минимум одного менеджера'),
  typicalActivities: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
        withDescription: yup.boolean().required(),
      }),
    )
    .required(),
  isNormalizedHours: yup.boolean().required(),
})
