import { useQuery } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { Project } from '@/entity/project'

interface UseProjectListProps {
  archived: boolean
}

export const useProjectList = (params?: UseProjectListProps) =>
  useQuery({
    queryKey: ['project', 'list'],
    queryFn: () => client.get<Project[]>('/projects', { params }).then(({ data }) => data),
  })
