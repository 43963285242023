import { ReactElement, useEffect, useId } from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { Controller, useForm, FieldErrors } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useAvailableProjectList } from '@/features/project'
import {
  getWorkTimeSlots,
  useCreateWorkLog,
  CreateWorkLogRecordForm,
  CreateWorkLogWithJiraIssue,
  CreateWorkLogWithTypicalActivity,
  WorkLog,
  useUpdateWorkLog,
  WorkLogPerDay,
} from '@/features/work-log'
import { Select } from '@/shared/ui-kit'
import { isDeepEqual } from '@/shared/utils'

import { validationSchema } from '../lib/validation'
import { User } from '@/entity/user'

interface CreateWorkLogRecordModalProps {
  isOpen: boolean
  userId?: User['id']
  currentDate: Date | null
  workLogToEdit: WorkLog | null
  onClose: () => void
  onSubmit: (workLogPerDay: WorkLogPerDay) => void
}

// TODO: Подправить типизацию
const CREATE_WORK_LOG_FORM_DEFAULT_VALUES = {
  project: null,
  date: new Date(),
  workType: 'CUSTOM',
  workDuration: null,
  description: '',
  customWorkJiraIssue: undefined,
  typicalWork: undefined,
} as unknown as CreateWorkLogRecordForm

export const CreateWorkLogRecordModal = ({
  isOpen,
  userId,
  currentDate,
  workLogToEdit,
  onClose,
  onSubmit,
}: CreateWorkLogRecordModalProps): ReactElement => {
  const toast = useToast()
  const { reset, setValue, getValues, watch, register, control, formState, handleSubmit } =
    useForm<CreateWorkLogRecordForm>({
      defaultValues: CREATE_WORK_LOG_FORM_DEFAULT_VALUES,
      resolver: yupResolver<CreateWorkLogRecordForm>(validationSchema),
      mode: 'onChange',
    })

  const projectId = useId()
  const workTypeId = useId()
  const workDurationId = useId()
  const customWorkJiraIssueId = useId()
  const descriptionId = useId()
  const projectActivityId = useId()

  const availableProjectList = useAvailableProjectList()
  const createWorkLog = useCreateWorkLog()
  const updateWorkLog = useUpdateWorkLog(workLogToEdit?.id)

  const workTypeValue = watch('workType')
  const projectActivityValue = watch('project')
  const typicalActivityValue = watch('typicalWork')

  const isProjectTypicalActivityExists = !!projectActivityValue?.typicalActivities?.length

  const isFormWithJiraIssue = workTypeValue === 'CUSTOM'
  const isFormWithTypicalActivity = workTypeValue === 'TYPICAL' && isProjectTypicalActivityExists
  const isDescriptionShown =
    isFormWithJiraIssue || (isFormWithTypicalActivity && !!typicalActivityValue?.withDescription)

  const formErrorsWithJiraIssue = formState.errors as FieldErrors<CreateWorkLogWithJiraIssue>
  const formErrorsWithTypicalActivity = formState.errors as FieldErrors<CreateWorkLogWithTypicalActivity>

  const isEditMode = !!workLogToEdit
  const actionTitle = isEditMode ? 'Редактировать' : 'Добавить'
  const workTimeSlots = getWorkTimeSlots()

  const handleModalClose = (): void => {
    onClose()
    reset()
  }

  const handleFormSubmit = (values: CreateWorkLogRecordForm): void => {
    const payload: CreateWorkLogRecordForm = {
      ...values,
      date: currentDate ?? new Date(),
      userId,
    }

    const mutationHook = isEditMode ? updateWorkLog : createWorkLog
    const toastTitle = isEditMode ? 'Активность отредактирована' : 'Активность добавлена'

    mutationHook.mutate(payload, {
      onSuccess: (workLogWithDebt) => {
        onSubmit(workLogWithDebt)
        reset()
        toast({
          title: toastTitle,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  useEffect(() => {
    const shouldAutoSelectProject =
      isOpen &&
      !isEditMode &&
      availableProjectList.isSuccess &&
      availableProjectList.data?.length === 1 &&
      !getValues('project')

    if (shouldAutoSelectProject) {
      setValue('project', availableProjectList.data[0])
    }
  }, [isOpen, isEditMode, availableProjectList.isSuccess, availableProjectList.data, getValues, setValue])

  useEffect(() => {
    const shouldAutoCompleteWorkLog =
      isOpen &&
      !!workLogToEdit &&
      !!availableProjectList.data &&
      isDeepEqual(getValues(), CREATE_WORK_LOG_FORM_DEFAULT_VALUES)

    if (!shouldAutoCompleteWorkLog) {
      return
    }

    setValue('workType', workLogToEdit?.workType)
    setValue('description', workLogToEdit?.description)

    if (workLogToEdit?.project) {
      const selectedProject = availableProjectList.data?.find(
        (availableProject) => availableProject.id === workLogToEdit.project.id,
      )

      if (selectedProject) {
        setValue('project', selectedProject)
      }

      if (workLogToEdit.workType === 'TYPICAL' && selectedProject?.typicalActivities) {
        const selectedTypicalActivity = selectedProject?.typicalActivities.find(
          (activity) => activity.name === workLogToEdit.typicalWork?.name,
        )
        if (selectedTypicalActivity) {
          setValue('typicalWork', selectedTypicalActivity)
        }
      }
    }

    if (workLogToEdit?.workDuration) {
      const selectedWorkDuration = workTimeSlots.find(
        (workTimeSlot) => workTimeSlot.value === workLogToEdit.workDuration,
      )

      if (selectedWorkDuration) {
        setValue('workDuration', selectedWorkDuration)
      }
    }

    if (workLogToEdit?.workType === 'CUSTOM' && workLogToEdit.customWork) {
      setValue('customWorkJiraIssue', workLogToEdit.customWork.jiraIssue)
    }
    // eslint-disable-next-line
  }, [workLogToEdit, isOpen, availableProjectList.data, workTimeSlots])

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>{actionTitle} активность</ModalHeader>
          <ModalCloseButton />

          <ModalBody display="flex" flexDir="column" rowGap={3}>
            <FormControl isInvalid={!!formState.errors.project}>
              <FormLabel htmlFor={projectId}>Проект</FormLabel>

              <Controller
                control={control}
                name="project"
                render={({ field }) => (
                  <Select
                    {...field}
                    inputId={projectId}
                    isClearable
                    filterOption={null}
                    placeholder="Выберите проект..."
                    loadingMessage={() => 'Загрузка...'}
                    isLoading={availableProjectList.isLoading}
                    options={availableProjectList.data}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(newValue) => {
                      setValue('workType', 'CUSTOM')
                      field.onChange(newValue)
                    }}
                  />
                )}
              />

              <FormErrorMessage>{formState.errors.project?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formState.errors.workType}>
              <FormLabel htmlFor={workTypeId}>Тип активности</FormLabel>

              <Controller
                control={control}
                name="workType"
                render={({ field }) => (
                  <RadioGroup value={field.value} onChange={field.onChange}>
                    <Stack direction="row">
                      <Radio value="CUSTOM">Jira таск</Radio>

                      {isProjectTypicalActivityExists && <Radio value="TYPICAL">Активность</Radio>}
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            {isFormWithJiraIssue && (
              <FormControl isRequired isInvalid={!!formErrorsWithJiraIssue.customWorkJiraIssue}>
                <FormLabel htmlFor={customWorkJiraIssueId}>JIRA таск</FormLabel>
                <Input id={customWorkJiraIssueId} placeholder="CHRON-50" {...register('customWorkJiraIssue')} />

                <FormErrorMessage>{formErrorsWithJiraIssue.customWorkJiraIssue?.message}</FormErrorMessage>
                <FormHelperText>Пример: CHRON-50 / MERCH-490 / TFR-23</FormHelperText>
              </FormControl>
            )}

            {isFormWithTypicalActivity && (
              <FormControl isRequired isInvalid={!!formErrorsWithTypicalActivity.typicalWork}>
                <FormLabel htmlFor={projectActivityId}>Активность</FormLabel>

                <Controller
                  control={control}
                  name="typicalWork"
                  render={({ field }) => (
                    <Select
                      {...field}
                      inputId={projectActivityId}
                      isClearable
                      filterOption={null}
                      placeholder="Выберите активность..."
                      loadingMessage={() => 'Загрузка...'}
                      options={projectActivityValue.typicalActivities ?? []}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                    />
                  )}
                />

                <FormErrorMessage>{formErrorsWithTypicalActivity.typicalWork?.message}</FormErrorMessage>
              </FormControl>
            )}

            {isDescriptionShown && (
              <FormControl isRequired isInvalid={!!formState.errors.description}>
                <FormLabel htmlFor={descriptionId}>Описание к таску</FormLabel>
                <Input id={descriptionId} placeholder="Пример: поменял цвет кнопки..." {...register('description')} />

                <FormErrorMessage>{formState.errors.description?.message}</FormErrorMessage>
              </FormControl>
            )}

            <FormControl isRequired isInvalid={!!formState.errors.workDuration}>
              <FormLabel htmlFor={workDurationId}>Часы</FormLabel>

              <Controller
                control={control}
                name="workDuration"
                render={({ field }) => (
                  <Select
                    {...field}
                    inputId={workDurationId}
                    isClearable
                    placeholder="Выберите время..."
                    options={workTimeSlots}
                    getOptionValue={(option) => option.value?.toString()}
                    getOptionLabel={(option) => option.label}
                  />
                )}
              />

              <FormErrorMessage>{formState.errors.workDuration?.message}</FormErrorMessage>
            </FormControl>

            <Input {...register('date')} type="hidden" />
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              colorScheme="teal"
              mr={3}
              isDisabled={formState.isSubmitting || !formState.isDirty || !formState.isValid}
              isLoading={createWorkLog.isPending || updateWorkLog.isPending}
            >
              {actionTitle} запись
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
