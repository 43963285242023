import { PropsWithChildren, ReactElement, useId } from 'react'
import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  FormLabel,
  FormErrorMessage,
  FormControl,
  Textarea,
  RadioGroup,
  Stack,
  Radio,
  FormHelperText,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useCreateFeedback } from '@/features/feedback'
import { Dropzone } from '@/shared/ui-kit'

import { CreateFeedbackForm } from '../model/create-feedback-form'
import { createUserFeedbackFormSchema } from '../validation/create-user-feedback-form-schema'

export const CreateUserFeedbackButton = ({ children, ...props }: PropsWithChildren<ButtonProps>): ReactElement => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { reset, setValue, watch, formState, control, register, handleSubmit } = useForm<CreateFeedbackForm>({
    defaultValues: {
      type: 'BUG_REPORT',
      comment: '',
      attachment: undefined,
    },
    mode: 'onChange',
    resolver: yupResolver<CreateFeedbackForm>(createUserFeedbackFormSchema),
  })

  const createFeedback = useCreateFeedback()

  const isBugReport = watch('type') === 'BUG_REPORT'
  const commentPlaceholder = isBugReport
    ? 'Как можно подробнее распишите что и где именно произошло не так'
    : 'Как можно подробнее распишите нам какие у тебя есть идеи для улучшения продукта, желательно с примерами'

  const commentId = useId()
  const attachmentId = useId()

  const handleFormSubmit = (values: CreateFeedbackForm): void => {
    createFeedback.mutate(values)
  }

  const handleFileDelete = (): void => {
    setValue('attachment', undefined, { shouldValidate: true })
  }

  const handleModalClose = (): void => {
    createFeedback.reset()
    reset()
    onClose()
  }

  return (
    <>
      <Button {...props} onClick={onOpen}>
        {children}
      </Button>

      <Modal isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton zIndex={20} />

          {createFeedback.isSuccess ? (
            <Alert
              status="success"
              variant="subtle"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Заявка успешно отправлена!
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Спасибо большое за твою обратную связь. Наша команда обязательно рассмотрит твой запрос.
              </AlertDescription>
            </Alert>
          ) : (
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <ModalHeader>Форма обратной связи</ModalHeader>

              <ModalBody display="flex" flexDir="column" rowGap={3}>
                <FormControl isRequired isInvalid={!!formState.errors.type?.message}>
                  <FormLabel>Тип обратной связи</FormLabel>

                  <Controller
                    control={control}
                    name="type"
                    render={({ field }) => (
                      <RadioGroup value={field.value} onChange={field.onChange}>
                        <Stack direction="row">
                          <Radio value="BUG_REPORT">Баг-репорт</Radio>
                          <Radio value="FEATURE_REQUEST">Идея для фичи</Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </FormControl>

                <FormControl isRequired isInvalid={!!formState.errors.comment?.message}>
                  <FormLabel htmlFor={commentId}>Описание</FormLabel>
                  <Textarea
                    id={commentId}
                    placeholder={commentPlaceholder}
                    minHeight="150px"
                    {...register('comment')}
                  />

                  <FormErrorMessage>{formState.errors.comment?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!formState.errors.attachment?.message}>
                  <FormLabel htmlFor={attachmentId}>Файл</FormLabel>

                  <Controller
                    control={control}
                    name="attachment"
                    render={({ field: { onChange, value } }) => (
                      <Dropzone value={value as unknown as string} onChange={onChange} onDelete={handleFileDelete} />
                    )}
                  />

                  <FormErrorMessage>{formState.errors.attachment?.message}</FormErrorMessage>
                  {isBugReport && (
                    <FormHelperText>
                      P.S Мы будем благодарны если вы запишите видео с багом, чтобы можно было понять как его
                      воспроизвести
                    </FormHelperText>
                  )}
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button
                  type="submit"
                  colorScheme="teal"
                  mr={3}
                  isDisabled={formState.isSubmitting || !formState.isDirty || !formState.isValid}
                  isLoading={createFeedback.isPending}
                >
                  Отправить
                </Button>

                <Button variant="outline" isDisabled={!formState.isDirty} onClick={() => reset()}>
                  Отменить
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
