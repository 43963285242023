import { format } from 'date-fns'

import { WORK_LOG_DATE_FORMAT } from '@/entity/work-log'

import { CreateWorkLogRecordForm } from '../../model/create-work-log-record-form'
import { isWorkLogFormWithJiraIssue } from '../../model/create-work-log-with-jira-issue'
import { isWorkLogFormWithTypicalActivity } from '../../model/create-work-log-with-typical-activity'

import {
  CreateWorkLogPayload,
  WorkLogBase,
  WorkLogWithJiraIssue,
  WorkLogWithTypicalActivity,
} from '../dto/create-work-log-payload'

export const serializeCreateWorkLog = (payload: CreateWorkLogRecordForm): CreateWorkLogPayload => {
  const workLogBase: WorkLogBase = {
    projectId: payload.project?.id as string,
    date: format(payload.date, WORK_LOG_DATE_FORMAT),
    workDuration: payload.workDuration?.value as number,
    workType: payload.workType,
    description: payload.description,
  }

  if (isWorkLogFormWithJiraIssue(payload)) {
    const jiraIssuePayload: WorkLogWithJiraIssue = {
      ...workLogBase,
      customWork: {
        jiraIssue: payload.customWorkJiraIssue,
      },
    }

    return jiraIssuePayload
  }

  if (isWorkLogFormWithTypicalActivity(payload)) {
    const typicalActivityPayload: WorkLogWithTypicalActivity = {
      ...workLogBase,
      typicalWork: {
        id: payload.typicalWork.id,
      },
    }

    return typicalActivityPayload
  }

  throw new Error('payload is not CreateWorkLogRecordForm type')
}
