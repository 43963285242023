import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { getFullDomainPath } from '@/shared/utils'

import { GoogleAuth } from '../../model/google-auth'

export const useGoogleAuth = () =>
  useMutation({
    mutationFn: (code: string) =>
      client
        .post<GoogleAuth>('auth/web/google/login', {
          code,
          host: getFullDomainPath(),
        })
        .then(({ data }) => data),
  })
