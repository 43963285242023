import { useCurrentUser } from '@/features/user/api'
import { UserRole } from '@/entity/user'

interface UserRoleReturnType {
  isLoading: boolean
  isUserHasRole: (userRole: UserRole | UserRole[]) => boolean
}

export const useUserRole = (): UserRoleReturnType => {
  const { isSuccess, isLoading, isPending, data } = useCurrentUser()

  const isUserHasRole = (role: UserRole | UserRole[]): boolean => {
    if (isSuccess) {
      const isArray = Array.isArray(role)
      const isSingleRoleMatch = !isArray && data.role === role
      const isAnyRoleMatch = isArray && role.includes(data.role)

      return isSingleRoleMatch || isAnyRoleMatch
    }

    return false
  }

  return {
    isLoading: isLoading || isPending,
    isUserHasRole,
  }
}
