import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { AddProjectMemberForm } from '@/features/project'

import { serializeProjectMember } from '../serializer/serialize-add-project-member'

export const useAddProjectMember = (projectId?: string) =>
  useMutation({
    mutationFn: (payload: AddProjectMemberForm) =>
      client.post(`/projects/${projectId}/members`, serializeProjectMember(payload)).then(({ data }) => data),
  })
