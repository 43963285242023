import { useQuery } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { ProjectActivity } from '@/entity/project'

export const useProjectActivityList = () =>
  useQuery({
    queryKey: ['project', 'list', 'activity'],
    queryFn: () => client.get<ProjectActivity[]>('/typical-activities').then(({ data }) => data),
  })
