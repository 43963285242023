import { AddProjectMemberPayload } from '../dto/add-project-member-payload'
import { AddProjectMemberForm } from '../../model/add-project-member-form'

export const serializeProjectMember = ({
  user,
  projectRoleName,
  ...values
}: AddProjectMemberForm): AddProjectMemberPayload => ({
  ...values,
  projectRoleName: projectRoleName?.name ?? '',
  userId: user?.id ?? '',
})
