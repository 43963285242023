import { PropsWithChildren, ReactElement } from 'react'
import { Box, Button, Skeleton, Text, VStack } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { format } from 'date-fns'

import { getMinutesToTime, MAX_ACTIVITY_MINUTES_PER_DAY, WorkLog } from '@/features/work-log'

interface DailyWorkLogPanelProps {
  options: WorkLog[]
  currentDate: Date | null
  onAddActivity: () => void
  renderOption: (option: WorkLog) => ReactElement
}

export const DailyWorkLogPanel = ({
  options,
  currentDate,
  onAddActivity,
  renderOption,
}: PropsWithChildren<DailyWorkLogPanelProps>): ReactElement => {
  const workLogsTimeSum = options.reduce((result, workLog) => result + workLog.workDuration, 0)

  return (
    <Box display="flex" flexDir="column" height="100%">
      <Box display="flex" flexDir="column" rowGap={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton isLoaded={!!currentDate} height="21px">
            {currentDate && <Text fontSize="sm">{format(currentDate, 'EEEE dd.MM')}</Text>}
          </Skeleton>

          <Skeleton isLoaded={!!currentDate} height="21px">
            {workLogsTimeSum > 0 && <Text fontSize="sm">{getMinutesToTime(workLogsTimeSum)}</Text>}
          </Skeleton>
        </Box>

        {workLogsTimeSum < MAX_ACTIVITY_MINUTES_PER_DAY && (
          <Button leftIcon={<AddIcon />} padding={4} onClick={onAddActivity}>
            Добавить активность
          </Button>
        )}
      </Box>

      <VStack overflow="auto" pb={8} height="100%" rowGap={2} mt={4}>
        {options.map(renderOption)}
      </VStack>
    </Box>
  )
}
