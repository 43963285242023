import { Size } from 'chakra-react-select'
import type { ResponsiveObject } from '@chakra-ui/system'
import { useBreakpointValue, useTheme } from '@chakra-ui/react'

// Код взят из внутренних настроек компонента chakra-react-select
// https://github.com/csandman/chakra-react-select/blob/main/src/chakra-components/menu.tsx

type SizeProp = Size | ResponsiveObject<Size> | Size[]

const getDefaultSize = (size: unknown): Size => {
  if (isSize(size)) {
    return size
  }

  if (size === 'xs') {
    return 'sm'
  }

  if (size === 'xl') {
    return 'lg'
  }

  return 'md'
}

const isSize = (size: unknown): size is Size => {
  const isString = typeof size === 'string'
  return isString && ['sm', 'md', 'lg'].includes(size)
}

export const useSize = (size: SizeProp | undefined): Size => {
  const chakraTheme = useTheme()
  const defaultSize = getDefaultSize(chakraTheme.components.Input.defaultProps.size)

  // Ensure that the size used is one of the options, either `sm`, `md`, or `lg`
  const definedSize: SizeProp = size ?? defaultSize
  // Or, if a breakpoint is passed, get the size based on the current screen size
  const realSize: Size =
    useBreakpointValue<Size>(typeof definedSize === 'string' ? [definedSize] : definedSize, {
      fallback: 'md',
    }) || defaultSize

  return realSize
}
