import { ReactElement, useState } from 'react'
import { Box, Button, IconButton, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { UseDisclosureReturn } from '@chakra-ui/hooks'

import { AddProjectActivityModal, DeleteProjectActivityModal, useProjectActivityList } from '@/features/project'
import { TableContainer } from '@/widgets/table-container'
import { ProjectActivity } from '@/entity/project'

export const ProjectActivityPage = (): ReactElement => {
  const addProjectActivityModal = useDisclosure()
  const deleteProjectActivityModal = useDisclosure()

  const projectActivityList = useProjectActivityList()

  const [selectedProjectActivity, setProjectActivity] = useState<ProjectActivity | null>(null)

  const handleModalSubmit = (modal: UseDisclosureReturn): void => {
    projectActivityList.refetch()
    handleModalClose(modal)
  }

  const handleModalOpen = (modal: UseDisclosureReturn, projectActivity: ProjectActivity): void => {
    if (projectActivity) {
      setProjectActivity(projectActivity)
    }

    modal.onOpen()
  }

  const handleModalClose = (modal: UseDisclosureReturn): void => {
    if (selectedProjectActivity) {
      setProjectActivity(null)
    }

    modal.onClose()
  }

  return (
    <Box display="flex" flexDir="column">
      <Box display="flex" justifyContent="flex-end" pb={4}>
        <Button leftIcon={<AddIcon />} onClick={addProjectActivityModal.onOpen}>
          Добавить активность проекта
        </Button>
      </Box>

      <TableContainer
        isLoading={projectActivityList.isLoading || projectActivityList.isFetching}
        isError={projectActivityList.isError}
        isEmpty={!projectActivityList.data?.length}
        onRetry={projectActivityList.refetch}
      >
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Название активности</Th>
              <Th>Описание</Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {projectActivityList.data?.map((projectActivity) => (
              <Tr key={projectActivity.id}>
                <Td>{projectActivity.name}</Td>
                <Td>{projectActivity.withDescription ? 'Да' : 'Нет'}</Td>
                <Td>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" columnGap={3}>
                    <IconButton aria-label="" onClick={() => handleModalOpen(addProjectActivityModal, projectActivity)}>
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      aria-label=""
                      onClick={() => handleModalOpen(deleteProjectActivityModal, projectActivity)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <AddProjectActivityModal
        isOpen={addProjectActivityModal.isOpen}
        projectActivityToEdit={selectedProjectActivity}
        onSuccess={() => handleModalSubmit(addProjectActivityModal)}
        onClose={() => handleModalClose(addProjectActivityModal)}
      />

      <DeleteProjectActivityModal
        isOpen={deleteProjectActivityModal.isOpen}
        projectActivityToDelete={selectedProjectActivity}
        onSuccess={() => handleModalSubmit(deleteProjectActivityModal)}
        onClose={() => handleModalClose(deleteProjectActivityModal)}
      />
    </Box>
  )
}
