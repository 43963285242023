import { createIcon } from '@chakra-ui/icons'

export const LogoutIcon = createIcon({
  displayName: 'LogoutIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 2.25H10V3.75H4.75V15.25H10V16.75H3.25V2.25ZM13.5694 5.51191L16.9878 9.5L13.5694 13.4881L12.4306 12.5119L14.3693 10.25H8V8.75H14.3693L12.4306 6.48809L13.5694 5.51191Z"
      fill="#B8BEC5"
    />
  ),
})
