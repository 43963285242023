import { useQuery } from '@tanstack/react-query'

import { ProjectRole } from '@/entity/project'
import { client } from '@/shared/config/axios.config'

export const useProjectRoleList = () =>
  useQuery({
    queryKey: ['project', 'list', 'role'],
    queryFn: () => client.get<ProjectRole[]>('/project-roles').then(({ data }) => data),
  })
