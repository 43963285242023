import { ReactElement } from 'react'
import { Box, Fade, Portal } from '@chakra-ui/react'
import { UseDisclosureReturn } from '@chakra-ui/hooks'

interface OverlayProps extends Pick<UseDisclosureReturn, 'isOpen' | 'onClose'> {}

export const Overlay = ({ isOpen, onClose }: OverlayProps): ReactElement | null => {
  if (isOpen) {
    return (
      <Portal>
        <Fade in={isOpen}>
          <Box
            position="fixed"
            top={0}
            left={0}
            height="100vh"
            width="100%"
            pointerEvents="auto"
            zIndex={100}
            bgColor="rgba(0,0,0,0.48)"
            onClick={() => onClose()}
          />
        </Fade>
      </Portal>
    )
  }

  return null
}
