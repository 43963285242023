import { ChangeEvent, ComponentProps, ReactElement, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, IconButton, Text } from '@chakra-ui/react'
import { FiUploadCloud } from 'react-icons/fi'
import { FaFile } from 'react-icons/fa'
import { DeleteIcon } from '@chakra-ui/icons'

interface DropzoneProps extends ComponentProps<'input'> {
  onDelete?: () => void
}

export const Dropzone = ({ value, onDelete, onChange }: DropzoneProps): ReactElement => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles.length || !onChange) {
        return
      }

      onChange(acceptedFiles[0] as unknown as ChangeEvent<HTMLInputElement>)
    },
    [onChange],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  const fileValue = value as unknown as File
  const dropZoneText = isDragActive
    ? `Перетащите файл сюда ...`
    : `Перетащите файл сюда или кликните, чтобы выбрать файл`

  return (
    <Box display="flex" flexDir="column" rowGap={3}>
      <Box
        p={8}
        borderWidth={2}
        borderRadius="md"
        borderStyle="dashed"
        rowGap={2}
        cursor="pointer"
        textAlign="center"
        display="flex"
        flexDir="column"
        alignItems="center"
        {...getRootProps()}
      >
        <input {...getInputProps({ onChange })} />
        <FiUploadCloud size={24} />
        <Text fontSize="sm">{dropZoneText}</Text>
      </Box>

      {!!fileValue && (
        <Box display="flex" flexDir="column" mb={2}>
          <Text fontWeight="500">Выбранный файл</Text>

          <Box display="flex" alignItems="center" justifyContent="space-between" ml={2}>
            <Box display="flex" alignItems="center" columnGap={1}>
              <FaFile />
              <Text fontSize="sm">{fileValue.name}</Text>
            </Box>

            {onDelete && (
              <IconButton size="sm" aria-label="" onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}
