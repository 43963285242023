import { ReactElement, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { UseModalProps } from '@chakra-ui/modal'

import { IdentifiableWithName } from '@/shared/model'
import { useDeleteDepartment } from '@/features/department'
import { toast } from '@/shared/ui-kit'

interface DeleteDepartmentFormModalProps extends Pick<UseModalProps, 'isOpen' | 'onClose'> {
  departmentToDelete: IdentifiableWithName | null
  onSuccess: () => void
}

export const DeleteDepartmentFormModal = ({
  isOpen,
  departmentToDelete,
  onSuccess,
  onClose,
}: DeleteDepartmentFormModalProps): ReactElement => {
  const cancelRef = useRef<HTMLButtonElement | null>(null)

  const deleteDepartment = useDeleteDepartment()

  const handleDeleteProjectRole = (): void => {
    if (!departmentToDelete?.id) {
      return
    }

    deleteDepartment.mutate(departmentToDelete.id, {
      onSuccess: () => {
        onSuccess()
        toast({
          title: `Департамент ${departmentToDelete.name} успешно удален`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Удаление роли
          </AlertDialogHeader>

          <AlertDialogBody>
            Вы действительно собираетесь удалить департамент {departmentToDelete?.name}?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="teal"
              isLoading={deleteDepartment.isPending || !departmentToDelete}
              onClick={handleDeleteProjectRole}
            >
              Удалить
            </Button>

            <Button ref={cancelRef} onClick={onClose} ml={3}>
              Отменить
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
