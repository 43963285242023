import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { CreateUserForm } from '../../model/create-user-form'
import { serializeCreateUser } from '../serializer/serialize-create-user'

export const useUpdateUser = (userId?: string) =>
  useMutation({
    mutationFn: (payload: CreateUserForm) =>
      client.put(`/users/${userId}`, serializeCreateUser(payload)).then(({ data }) => data),
  })
