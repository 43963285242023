import { CreateUserForm } from '../../model/create-user-form'
import { CreateUserPayload } from '../dto/create-user-payload'

export const serializeCreateUser = ({
  department,
  defaultProjectRole,
  ...values
}: CreateUserForm): CreateUserPayload => ({
  ...values,
  departmentId: department?.id ?? '',
  defaultProjectRoleId: defaultProjectRole?.id ?? '',
})
