import { ReactElement, useEffect, useId } from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { UseModalProps } from '@chakra-ui/modal'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { CreateProjectActivityForm, useCreateProjectActivity, useUpdateProjectActivity } from '@/features/project'
import { ProjectActivity } from '@/entity/project'
import { toast } from '@/shared/ui-kit'

import { createProjectActivityValidation } from '../lib/validation/create-project-activity-validation'

interface AddProjectActivityModalProps extends Pick<UseModalProps, 'isOpen' | 'onClose'> {
  onSuccess: () => void
  projectActivityToEdit: ProjectActivity | null
}

const ADD_PROJECT_ACTIVITY_FORM_DEFAULT_VALUES: CreateProjectActivityForm = {
  name: '',
  withDescription: false,
}

export const AddProjectActivityModal = ({
  isOpen,
  projectActivityToEdit,
  onClose,
  onSuccess,
}: AddProjectActivityModalProps): ReactElement => {
  const { reset, handleSubmit, register, formState } = useForm<CreateProjectActivityForm>({
    defaultValues: ADD_PROJECT_ACTIVITY_FORM_DEFAULT_VALUES,
    resolver: yupResolver<CreateProjectActivityForm>(createProjectActivityValidation),
  })

  const createProjectActivity = useCreateProjectActivity()
  const editProjectActivity = useUpdateProjectActivity(projectActivityToEdit?.id)

  const projectActivityId = useId()

  const isEditMode = !!projectActivityToEdit
  const actionTitle = isEditMode ? 'Редактировать' : 'Добавить'

  const handleFormSubmit = (values: CreateProjectActivityForm): void => {
    const mutationHook = isEditMode ? editProjectActivity : createProjectActivity
    const toastTitle = isEditMode
      ? `Активность ${values.name} успешно отредактирована`
      : `Активность ${values.name} успешно добавлена`

    mutationHook.mutate(values, {
      onSuccess: () => {
        onSuccess()
        reset(ADD_PROJECT_ACTIVITY_FORM_DEFAULT_VALUES)
        toast({
          title: toastTitle,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const handleModalClose = (): void => {
    onClose()
    reset(ADD_PROJECT_ACTIVITY_FORM_DEFAULT_VALUES)
  }

  useEffect(() => {
    if (isOpen && projectActivityToEdit) {
      reset({
        name: projectActivityToEdit.name,
        withDescription: projectActivityToEdit.withDescription,
      })
    }
  }, [isOpen, projectActivityToEdit, reset])

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />

      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>{actionTitle} проектную активность</ModalHeader>
          <ModalCloseButton />

          <ModalBody display="flex" flexDir="column" rowGap={3}>
            <FormControl isInvalid={!!formState.errors.name?.message}>
              <FormLabel htmlFor={projectActivityId}>Типичная активность</FormLabel>
              <Input
                id={projectActivityId}
                placeholder="Введите название активности"
                autoComplete="off"
                {...register('name')}
              />

              <FormErrorMessage>{formState.errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl>
              <Checkbox colorScheme="teal" {...register('withDescription')}>
                Включить описание
              </Checkbox>

              <FormHelperText>
                При выборе этой активности сотрудникам нужно дополнительно описать проведённую работу.
              </FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              colorScheme="teal"
              mr={3}
              isDisabled={formState.isSubmitting || !formState.isValid || !formState.isDirty}
              isLoading={editProjectActivity.isPending || createProjectActivity.isPending}
            >
              {actionTitle}
            </Button>

            <Button variant="outline" isDisabled={!formState.isDirty} onClick={() => reset()}>
              Отменить
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
