import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

import { client } from '@/shared/config/axios.config'

import { WorkLogWithDebt } from '../../model/work-log-with-debt'
import { WorkLogPerDay } from '../../model/work-log-per-day'
import { WorkLogPayload } from '../../model/work-log-payload'
import { getWorkLogParams } from '../../utils/get-work-log-params'

const getWorkLogHashmap = (payload: WorkLogPerDay): Map<string, WorkLogPerDay> => {
  const hashmap = new Map<string, WorkLogPerDay>()
  if (!Array.isArray(payload)) {
    return hashmap
  }

  return payload.reduce((result, workLog) => {
    if (!result.has(workLog.date)) {
      result.set(workLog.date, workLog)
    }

    return result
  }, hashmap)
}

export const useWorkLog = (payload: WorkLogPayload) => {
  const params = getWorkLogParams(payload)

  return useQuery({
    queryKey: ['work-log', params],
    queryFn: () => client.get<WorkLogWithDebt>('/v2/logs', { params }).then(({ data }) => data),
    select: useCallback(({ totalDebt, logWorksPerDay }: WorkLogWithDebt) => {
      const hashmap = getWorkLogHashmap(logWorksPerDay)

      return {
        totalDebt,
        hashmap,
      }
    }, []),
  })
}
