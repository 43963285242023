import yup from '@/shared/config/yup.config'
import { WorkLogType } from '@/entity/work-log'
import { ProjectType } from '@/entity/project'

export const validationSchema = yup.object({
  project: yup
    .object({
      id: yup.string().required(),
      name: yup.string().required(),
      type: yup.mixed<ProjectType>().oneOf(['COMMERCIAL', 'NONCOMMERCIAL', 'INVEST']).required(),
      typicalActivities: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.string().required(),
            name: yup.string().required(),
            withDescription: yup.boolean().required(),
          }),
        )
        .required(),
    })
    .required(),
  date: yup.date().required(),
  workType: yup.mixed<WorkLogType>().oneOf(['TYPICAL', 'CUSTOM']).required(),
  workDuration: yup
    .object({
      label: yup.string().required(),
      value: yup.number().required(),
    })
    .required(),
  customWorkJiraIssue: yup.string().when('workType', ([workType], schema) =>
    (workType as unknown as WorkLogType) === 'CUSTOM'
      ? schema
          .required()
          .test({
            test: (jiraValue) => /\b[A-Z]+-\d+\b/.test(jiraValue),
            message: 'Значение должны быть в формате Jira таска',
          })
          .max(30, 'Максимальная длина строки - 30 символов')
      : schema,
  ),
  typicalWork: yup
    .object({
      id: yup.string().required(),
      name: yup.string().required(),
      withDescription: yup.boolean().required(),
    })
    .default(undefined)
    .when('workType', ([workType], schema) =>
      (workType as unknown as WorkLogType) === 'TYPICAL' ? schema.required() : schema,
    ),
  description: yup
    .string()
    .default('')
    .when(['workType', 'typicalWork'], ([workType, typicalWork], schema) => {
      const isTypicalWork = (workType as unknown as WorkLogType) === 'TYPICAL'
      const isTypicalWorkWithDescription = isTypicalWork && !!typicalWork?.withDescription

      return isTypicalWorkWithDescription || !isTypicalWork ? schema.required() : schema
    }),
  userId: yup.string().default(undefined),
})
