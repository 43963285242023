import { useMutation } from '@tanstack/react-query'

import { ProjectAggregatedReportPreview, ProjectDetailedReportPreview } from '@/entity/report'
import { client } from '@/shared/config/axios.config'

import { CreateProjectReportForm } from '../../model/create-project-report-form'
import { serializeCreateProjectReport } from '../serializer/serialize-create-project-report'

export const useCreatePreviewProjectReport = () =>
  useMutation({
    mutationFn: (payload: CreateProjectReportForm) =>
      client
        .post<
          ProjectDetailedReportPreview | ProjectAggregatedReportPreview
        >('/reports/generate/project/preview', serializeCreateProjectReport(payload))
        .then(({ data }) => data),
  })
