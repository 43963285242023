import { ReactElement, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { UseModalProps } from '@chakra-ui/modal'
import { useParams } from 'react-router-dom'

import { ProjectMember } from '@/entity/project'
import { useDeleteProjectMember } from '@/features/project'
import { toast } from '@/shared/ui-kit'

interface DeleteProjectRoleModalProps extends Pick<UseModalProps, 'isOpen' | 'onClose'> {
  projectMemberToDelete: ProjectMember | null
  onSuccess: () => void
}

export const DeleteProjectMemberModal = ({
  isOpen,
  projectMemberToDelete,
  onSuccess,
  onClose,
}: DeleteProjectRoleModalProps): ReactElement => {
  const { projectId } = useParams()

  const cancelRef = useRef<HTMLButtonElement | null>(null)

  const deleteProjectRole = useDeleteProjectMember(projectId)

  const handleDeleteProjectRole = (): void => {
    if (!projectMemberToDelete?.user.id) {
      return
    }

    deleteProjectRole.mutate(projectMemberToDelete.user.id, {
      onSuccess: () => {
        onSuccess()
        toast({
          title: `Пользователь ${projectMemberToDelete.user.displayName} удален из проекта`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Удаление роли
          </AlertDialogHeader>

          <AlertDialogBody>
            Вы действительно собираетесь удалить пользователя {projectMemberToDelete?.user.displayName} из проекта?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="teal"
              isLoading={deleteProjectRole.isPending || !projectMemberToDelete}
              onClick={handleDeleteProjectRole}
            >
              Удалить
            </Button>

            <Button ref={cancelRef} onClick={onClose} ml={3}>
              Отменить
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
