import { useQuery } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { User } from '@/entity/user'

export const useUserList = () =>
  useQuery({
    queryKey: ['user', 'list'],
    queryFn: () => client.get<User[]>('/users').then(({ data }) => data),
  })
