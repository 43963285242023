import { useQuery } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { User } from '@/entity/user'

export const useCurrentUser = () =>
  useQuery({
    queryKey: ['user', 'current'],
    queryFn: () => client.get<User>('/users/current').then(({ data }) => data),
  })
