import { ReactElement } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  Avatar,
  Box,
  Divider,
  Button,
  IconButton,
  Text,
  VStack,
  SkeletonCircle,
  SkeletonText,
  Skeleton,
  useDisclosure,
  AvatarBadge,
  Fade,
} from '@chakra-ui/react'
import { FaAngleLeft, FaAngleRight, FaComment } from 'react-icons/fa'

import { useCurrentUser } from '@/features/user'
import { CreateUserFeedbackButton } from '@/features/feedback'
import { USER_ROLE_TITLE, UserRole } from '@/entity/user'
import { LogoutIcon, Overlay, TekmatesIcon } from '@/shared/ui-kit'
import { GOOGLE_TOKEN_STORAGE_KEY } from '@/shared/constants'

import { SIDEBAR_NAVIGATION_MENU } from '../lib/constants'

export const Sidebar = (): ReactElement => {
  const sidebar = useDisclosure()

  const navigate = useNavigate()
  const currentUser = useCurrentUser()

  const fullName = [currentUser.data?.firstName, currentUser.data?.lastName].join(' ')

  // TODO: Отрефакторить таким образом чтобы не дублировать код из routes и здесь
  const sidebarNavigationMenu = SIDEBAR_NAVIGATION_MENU.filter(
    ({ role }) =>
      role === undefined || currentUser.data?.role === role || role.includes(currentUser.data?.role as UserRole),
  )

  const handleSidebarChange = (): void => {
    if (sidebar.isOpen) {
      sidebar.onClose()
      return
    }

    sidebar.onOpen()
  }
  const handleLogout = (): void => {
    localStorage.removeItem(GOOGLE_TOKEN_STORAGE_KEY)
    navigate('/')
  }

  return (
    <>
      <Box
        position="fixed"
        h="100%"
        display="flex"
        flexDir="column"
        boxShadow="md"
        bgColor="#1A202C"
        borderRight="1px solid rgba(255, 255, 255, 0.16)"
        width={sidebar.isOpen ? '350px' : '80px'}
        transition="0.3s width ease"
        zIndex={1000}
      >
        <Box display="flex" flexDir="column" p={4}>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <SkeletonCircle size="3rem" isLoaded={!currentUser.isLoading}>
              <Avatar bg="teal.500" size="md" name={fullName}>
                <AvatarBadge boxSize="1em" bg="green.500" />
              </Avatar>
            </SkeletonCircle>

            <Fade transition={{ enter: { delay: 0.1 } }} in={sidebar.isOpen} style={{ flex: 1 }}>
              {sidebar.isOpen && (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <SkeletonText isLoaded={!currentUser.isLoading} ml={3}>
                    <Text fontSize="14" fontWeight="600" textOverflow="ellipsis" whiteSpace="nowrap">
                      {fullName}
                    </Text>

                    {currentUser.data?.role && <Text fontSize="14">{USER_ROLE_TITLE[currentUser.data.role]}</Text>}
                  </SkeletonText>

                  <IconButton
                    ml="auto"
                    variant="ghost"
                    size="xs"
                    fontSize="16px"
                    aria-label="Выйти"
                    onClick={handleLogout}
                  >
                    <LogoutIcon />
                  </IconButton>
                </Box>
              )}
            </Fade>
          </Box>

          <Box display="flex" alignItems="center" mt={4} justifyContent={sidebar.isOpen ? 'space-between' : 'center'}>
            {sidebar.isOpen && <Text fontSize="14">Tekmates</Text>}

            <IconButton
              variant="outline"
              aria-label="Выйти"
              size="xs"
              fontSize="16px"
              icon={sidebar.isOpen ? <FaAngleLeft /> : <FaAngleRight />}
              onClick={handleSidebarChange}
            />
          </Box>
        </Box>

        <Divider />

        <Box p={4} flex="1" display="flex" flexDir="column" justifyContent="space-between">
          {currentUser.isLoading ? (
            <Skeleton height="32px" width="100%" borderRadius={4} />
          ) : (
            <VStack>
              {sidebarNavigationMenu.map(({ icon: Icon, label, to }) => (
                <Box key={label} as={NavLink} to={to} width="100%">
                  {({ isActive }: { isActive: boolean }) => (
                    <Button
                      size="md"
                      width="100%"
                      fontSize="14px"
                      display="flex"
                      justifyContent="flex-start"
                      bgColor="transparent"
                      leftIcon={<Icon size="16px" />}
                      aria-current={isActive ? 'page' : undefined}
                      _activeLink={{
                        bg: { base: 'teal.900' },
                        color: 'teal.100',
                      }}
                      onClick={sidebar.onClose}
                    >
                      <Fade transition={{ enter: { delay: 0.1 } }} in={sidebar.isOpen}>
                        {sidebar.isOpen && label}
                      </Fade>
                    </Button>
                  )}
                </Box>
              ))}

              <CreateUserFeedbackButton
                size="md"
                width="100%"
                fontSize="14px"
                display="flex"
                justifyContent="flex-start"
                bgColor="transparent"
                leftIcon={<FaComment />}
              >
                <Fade transition={{ enter: { delay: 0.1 } }} in={sidebar.isOpen}>
                  {sidebar.isOpen && 'Форма обратной связи'}
                </Fade>
              </CreateUserFeedbackButton>
            </VStack>
          )}

          <Fade transition={{ enter: { delay: 0.1 } }} in={sidebar.isOpen}>
            {sidebar.isOpen && <TekmatesIcon height="auto" width="160px" p={4} />}
          </Fade>
        </Box>
      </Box>

      <Overlay isOpen={sidebar.isOpen} onClose={sidebar.onClose} />
    </>
  )
}
