import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { CreateHolidayForm } from '../../model/create-holiday-form'
import { serializeCreateHolidayForm } from '../serializer/serialize-create-holiday-form'

export const useCreateHoliday = () =>
  useMutation({
    mutationFn: (payload: CreateHolidayForm) =>
      client.post('/dictionaries/holidays', serializeCreateHolidayForm(payload)).then(({ data }) => data),
  })
