import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { CreateProjectActivityForm } from '@/features/project'
import { IdentifiableWithName } from '@/shared/model'

export const useUpdateProjectActivity = (activityId?: string) =>
  useMutation({
    mutationFn: (payload: CreateProjectActivityForm) =>
      client.put<IdentifiableWithName[]>(`/typical-activities/${activityId}`, payload).then(({ data }) => data),
  })
