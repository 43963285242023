import { HolidaySchedule } from '@/entity/holiday'

import { CreateHolidayForm } from '../../model/create-holiday-form'

export const serializeCreateHolidayForm = (payload: CreateHolidayForm): HolidaySchedule => ({
  year: new Date().getFullYear().toString(),
  days: payload.holidays.map((holiday) => {
    const date = new Date(holiday.date)

    return {
      day: date.getDate(),
      month: date.getMonth(),
    }
  }),
})
