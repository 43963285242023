import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { getFullDomainPath } from '@/shared/utils'

import { GoogleUrl } from '../../model/google-url'

export const useGoogleUrl = () =>
  useMutation({
    mutationFn: () =>
      client
        .post<GoogleUrl>('/auth/web/google/url', {
          host: getFullDomainPath(),
        })
        .then(({ data }) => data),
  })
