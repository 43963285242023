import { endOfMonth, format, isAfter } from 'date-fns'

import { WORK_LOG_DATE_FORMAT } from '@/entity/work-log'

export const getDateTo = (date: Date) => {
  const lastDayOfDateMonth = endOfMonth(date)
  const selectedDate = isAfter(lastDayOfDateMonth, new Date()) ? new Date() : lastDayOfDateMonth

  return format(selectedDate, WORK_LOG_DATE_FORMAT)
}
