import { ReactElement, useEffect, useId } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select as ChakraSelect,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { UseModalProps } from '@chakra-ui/modal'

import { User, UserRole } from '@/entity/user'
import { useProjectRoleList } from '@/features/project'
import { useDepartmentList } from '@/features/department'
import { CreateUserForm, useCreateUser, useUpdateUser } from '@/features/user'
import { Select, toast } from '@/shared/ui-kit'

import { validationSchema } from '../lib/validation'

interface CreateUserModalProps extends Pick<UseModalProps, 'isOpen' | 'onClose'> {
  userToEdit: User | null
  onSuccess: () => void
}

const CREATE_USER_FORM_DEFAULT_VALUES: CreateUserForm = {
  email: '',
  role: UserRole.EMPLOYEE,
  department: null,
  firstName: '',
  lastName: '',
  workLogRequired: true,
  defaultProjectRole: null,
}

export const CreateUserModal = ({ isOpen, userToEdit, onSuccess, onClose }: CreateUserModalProps): ReactElement => {
  const { reset, control, handleSubmit, register, formState } = useForm<CreateUserForm>({
    defaultValues: CREATE_USER_FORM_DEFAULT_VALUES,
    resolver: yupResolver<CreateUserForm>(validationSchema),
  })

  const emailId = useId()
  const roleId = useId()
  const departmentId = useId()
  const firstNameId = useId()
  const lastNameId = useId()
  const defaultProjectRoleId = useId()

  const updateUser = useUpdateUser(userToEdit?.id)
  const createUser = useCreateUser()

  const departmentList = useDepartmentList()
  const projectRoleList = useProjectRoleList()

  const isEditMode = !!userToEdit
  const actionTitle = isEditMode ? 'Редактировать' : 'Создать'

  const handleCreateUserSubmit = (values: CreateUserForm): void => {
    const fullName = [values.firstName, values.lastName].join(' ')
    const mutationHook = isEditMode ? updateUser : createUser
    const toastTitle = isEditMode
      ? `Пользователь ${fullName} успешно отредактирован`
      : `Пользователь ${fullName} успешно добавлен`

    mutationHook.mutate(values, {
      onSuccess: () => {
        onSuccess()
        reset(CREATE_USER_FORM_DEFAULT_VALUES)
        toast({
          title: toastTitle,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const handleModalClose = (): void => {
    onClose()
    reset(CREATE_USER_FORM_DEFAULT_VALUES)
  }

  useEffect(() => {
    if (isOpen && userToEdit) {
      reset({
        ...CREATE_USER_FORM_DEFAULT_VALUES,
        ...userToEdit,
      })
    }
  }, [isOpen, reset, userToEdit])

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleCreateUserSubmit)}>
          <ModalHeader>{actionTitle} пользователя</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Box display="flex" flexDir="column" rowGap={3}>
              <FormControl isRequired isInvalid={!!formState.errors.firstName}>
                <FormLabel htmlFor={firstNameId}>Имя</FormLabel>

                <Input id={firstNameId} placeholder="Введите имя пользователя" {...register('firstName')} />

                <FormErrorMessage>{formState.errors.firstName?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!formState.errors.lastName}>
                <FormLabel htmlFor={lastNameId}>Фамилия</FormLabel>

                <Input id={lastNameId} placeholder="Введите фамилию пользователя" {...register('lastName')} />

                <FormErrorMessage>{formState.errors.lastName?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!formState.errors.email}>
                <FormLabel htmlFor={emailId}>Почта</FormLabel>

                <Input
                  id={emailId}
                  type="email"
                  placeholder="Введите email пользователя"
                  isDisabled={isEditMode}
                  {...register('email')}
                />

                <FormErrorMessage>{formState.errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!formState.errors.role}>
                <FormLabel htmlFor={roleId}>Роль</FormLabel>

                <ChakraSelect id={roleId} {...register('role')}>
                  <option value="ADMIN">Админ</option>
                  <option value="MANAGER">Менеджер</option>
                  <option value="EMPLOYEE">Сотрудник</option>
                  <option value="OUTSTAFF">Аутстафф</option>
                </ChakraSelect>

                <FormErrorMessage>{formState.errors.role?.message}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box display="flex" flexDir="column" rowGap={3} mt={8}>
              <FormControl isInvalid={!!formState.errors.department?.message}>
                <FormLabel htmlFor={departmentId}>Департамент</FormLabel>

                <Controller
                  control={control}
                  name="department"
                  render={({ field }) => (
                    <Select
                      {...field}
                      inputId={departmentId}
                      isClearable
                      placeholder="Выберите департамент..."
                      noOptionsText="Данный департамент отсутствует"
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isLoading={departmentList.isLoading}
                      options={departmentList.data}
                    />
                  )}
                />

                <FormErrorMessage>{formState.errors.department?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formState.errors.defaultProjectRole?.message}>
                <FormLabel htmlFor={defaultProjectRoleId}>Роль в проекте по умолчанию</FormLabel>

                <Controller
                  control={control}
                  name="defaultProjectRole"
                  render={({ field }) => (
                    <Select
                      {...field}
                      inputId={defaultProjectRoleId}
                      isClearable
                      placeholder="Выберите роль..."
                      noOptionsText="Данная роль отсутствует"
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isLoading={projectRoleList.isLoading}
                      options={projectRoleList.data}
                    />
                  )}
                />

                <FormErrorMessage>{formState.errors.defaultProjectRole?.message}</FormErrorMessage>
              </FormControl>
            </Box>

            <FormControl mt={8}>
              <Checkbox colorScheme="teal" defaultChecked={!isEditMode} {...register('workLogRequired')}>
                Должен списывать часы в календарь
              </Checkbox>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              colorScheme="teal"
              mr={3}
              isDisabled={formState.isSubmitting || !formState.isDirty}
              isLoading={updateUser.isPending || createUser.isPending}
            >
              {actionTitle}
            </Button>

            <Button variant="outline" isDisabled={!formState.isDirty} onClick={() => reset()}>
              Очистить
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
