import { ReactElement, useId } from 'react'
import { Box, Button, FormControl, FormLabel, Input, Skeleton, chakra } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'

import { useDepartmentList } from '@/features/department'
import { FilterProjectListForm } from '@/features/project'
import { Select } from '@/shared/ui-kit'

interface FilterProjectListProps {
  onChange: (values: FilterProjectListForm) => void
}

const FILTER_PROJECT_LIST_DEFAULT_VALUES = {
  name: '',
  department: [],
}

export const FilterProjectList = ({ onChange }: FilterProjectListProps): ReactElement => {
  const { formState, register, control, handleSubmit } = useForm<FilterProjectListForm>({
    defaultValues: FILTER_PROJECT_LIST_DEFAULT_VALUES,
  })

  const projectNameId = useId()
  const departmentId = useId()

  const departmentList = useDepartmentList()

  return (
    <Box display="flex" alignItems="center" pt={4} pb={8}>
      <chakra.form onSubmit={handleSubmit(onChange)}>
        <Box display="flex" alignItems="flex-end" justifyContent="center" columnGap={4}>
          <FormControl width="300px">
            <FormLabel htmlFor={projectNameId}>Название проекта</FormLabel>
            <Input id={projectNameId} placeholder="Введите название проекта" autoComplete="off" {...register('name')} />
          </FormControl>

          <FormControl flex={1}>
            <FormLabel htmlFor={departmentId}>Департамент</FormLabel>

            <Skeleton isLoaded={!departmentList.isLoading}>
              <Controller
                control={control}
                name="department"
                render={({ field }) => (
                  <Select
                    {...field}
                    inputId={departmentId}
                    isMulti
                    isClearable
                    placeholder="Выберите департамент..."
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    isLoading={departmentList.isLoading}
                    options={departmentList.data ?? []}
                  />
                )}
              />
            </Skeleton>
          </FormControl>

          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={formState.isSubmitting}
            isLoading={departmentList.isPending}
          >
            Применить фильтры
          </Button>
        </Box>
      </chakra.form>
    </Box>
  )
}
