import { ReactElement, useEffect, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useDisclosure,
  Heading,
} from '@chakra-ui/react'
import { AddIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
import { UseDisclosureReturn } from '@chakra-ui/hooks'
import { useNavigate } from 'react-router-dom'
import { MdPower, MdPowerOff } from 'react-icons/md'

import { Project, PROJECT_COMMERCIAL_TITLE } from '@/entity/project'
import { ActivateProjectModal, ArchiveProjectModal, FilterProjectListForm, useProjectList } from '@/features/project'
import { CreateProjectModal } from '@/widgets/create-project-modal'
import { TableContainer } from '@/widgets/table-container'
import { FilterProjectList } from '@/widgets/filter-project-list'

export const ProjectPage = (): ReactElement => {
  const navigate = useNavigate()

  const createProjectModal = useDisclosure()
  const archiveProjectModal = useDisclosure()
  const activateProjectModal = useDisclosure()

  const projectList = useProjectList()

  const [selectedProject, setSelectedProject] = useState<Project | null>(null)
  const [filteredProjectList, setFilteredProjectList] = useState<Project[]>([])

  const handleModalOpen = (modal: UseDisclosureReturn, project: Project): void => {
    if (project) {
      setSelectedProject(project)
    }

    modal.onOpen()
  }

  const handleModalClose = (modal: UseDisclosureReturn): void => {
    if (selectedProject) {
      setSelectedProject(null)
    }

    modal.onClose()
  }

  const handleModalSubmit = (modal: UseDisclosureReturn): void => {
    projectList.refetch()
    handleModalClose(modal)
  }

  const handleFilterChange = (values: FilterProjectListForm): void => {
    if (!projectList.data?.length || (!values.name && !values.department.length)) {
      setFilteredProjectList([])
    }

    let filteredProjectList = projectList.data as Project[]

    if (values.name) {
      filteredProjectList = filteredProjectList.filter(({ name }) =>
        name.toLowerCase().includes(values.name.toLowerCase().trim()),
      )
    }

    if (values.department.length) {
      const departmentList = new Set(values.department.map((department) => department.name))
      filteredProjectList = filteredProjectList.filter((filteredProject) =>
        departmentList.has(filteredProject.department?.name),
      )
    }

    setFilteredProjectList(filteredProjectList)
  }

  useEffect(() => {
    if (projectList.isSuccess && projectList.data) {
      setFilteredProjectList(projectList.data)
    }
  }, [projectList.isSuccess, projectList.data])

  return (
    <Box display="flex" flexDir="column">
      <Box display="flex" justifyContent="space-between">
        <Heading size="lg">Список проектов</Heading>

        <Button leftIcon={<AddIcon />} onClick={createProjectModal.onOpen}>
          Создать проект
        </Button>
      </Box>

      <FilterProjectList onChange={handleFilterChange} />

      <TableContainer
        isLoading={projectList.isLoading || projectList.isFetching}
        isEmpty={!projectList.data?.length}
        isError={projectList.isError}
        onRetry={projectList.refetch}
      >
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Название проекта</Th>
              <Th>Тип</Th>
              <Th>Активен</Th>
              <Th>Логика приведения часов</Th>
              <Th>Менеджер/-ы</Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {filteredProjectList.map((project) => (
              <Tr key={project.id}>
                <Td>{project.name}</Td>
                <Td>{PROJECT_COMMERCIAL_TITLE[project.type]}</Td>
                <Td>{!project.isArchived ? 'Да' : 'Нет'}</Td>
                <Td>{project.isNormalizedHours ? 'Включена' : 'Отсутствует'}</Td>
                <Td>
                  {project.managers.length <= 1 ? (
                    <Text>{project.managers[0]?.displayName ?? '-'}</Text>
                  ) : (
                    <Box display="flex" flexDir="column" rowGap={2}>
                      {project.managers.map((manager) => (
                        <Text key={manager.id}>{manager.displayName}</Text>
                      ))}
                    </Box>
                  )}
                </Td>
                <Td>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" columnGap={3}>
                    <IconButton aria-label="" onClick={() => navigate(`/dashboard/projects/${project.id}`)}>
                      <ViewIcon />
                    </IconButton>

                    <IconButton aria-label="" onClick={() => handleModalOpen(createProjectModal, project)}>
                      <EditIcon />
                    </IconButton>
                    {!project.isArchived ? (
                      <IconButton aria-label="" onClick={() => handleModalOpen(archiveProjectModal, project)}>
                        <MdPowerOff />
                      </IconButton>
                    ) : (
                      <IconButton aria-label="" onClick={() => handleModalOpen(activateProjectModal, project)}>
                        <MdPower />
                      </IconButton>
                    )}
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <ArchiveProjectModal
          isOpen={archiveProjectModal.isOpen}
          projectToArchive={selectedProject}
          onSuccess={() => handleModalSubmit(archiveProjectModal)}
          onClose={() => handleModalClose(archiveProjectModal)}
        />

        <ActivateProjectModal
          isOpen={activateProjectModal.isOpen}
          projectToActivate={selectedProject}
          onSuccess={() => handleModalSubmit(activateProjectModal)}
          onClose={() => handleModalClose(activateProjectModal)}
        />
      </TableContainer>

      <CreateProjectModal
        isOpen={createProjectModal.isOpen}
        projectToEdit={selectedProject}
        onSuccess={() => handleModalSubmit(createProjectModal)}
        onClose={() => handleModalClose(createProjectModal)}
      />
    </Box>
  )
}
