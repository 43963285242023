import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { CreateDailyReportForm } from '@/features/report'

import { serializeCreateDailyReport } from '../serializer/serialize-create-daily-report'

export const useCreateDailyReport = () =>
  useMutation({
    mutationFn: (payload: CreateDailyReportForm) =>
      client
        .post<Blob>('/reports/generate/daily', serializeCreateDailyReport(payload), {
          responseType: 'blob',
        })
        .then(({ data }) => data),
  })
