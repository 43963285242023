import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton } from '@chakra-ui/react'
import { User } from '@/entity/user'

interface WorkLogCalendarViewingAlertProps {
  user?: User
}

export const WorkLogCalendarViewingAlert: FC<WorkLogCalendarViewingAlertProps> = ({ user }) => {
  const navigate = useNavigate()

  const handleUserCalendarClose = (): void => {
    navigate('/dashboard/users')
  }

  return (
    <Alert status="info">
      <AlertIcon />

      <Box width="100%">
        <AlertTitle>Внимание!</AlertTitle>
        <AlertDescription>
          Вы просматриваете часы за пользователя:{' '}
          <b>
            {user?.firstName} {user?.lastName}
          </b>
        </AlertDescription>
      </Box>

      <CloseButton alignSelf="flex-start" position="relative" right={-1} top={-1} onClick={handleUserCalendarClose} />
    </Alert>
  )
}
