import { ReactElement } from 'react'
import ReactCalendar, { OnArgs, TileArgs } from 'react-calendar'
import { Box, Text } from '@chakra-ui/react'
import { format } from 'date-fns'

import { WORK_LOG_DATE_FORMAT } from '@/entity/work-log'

import { WorkLogPerDay } from '../model/work-log-per-day'
import { getMinutesToTime } from '../utils/get-minutes-to-time'

import './work-log-calendar.css'

interface WorkLogCalendarProps {
  workLogMap?: Map<string, WorkLogPerDay>
  currentDate: Date | null
  currentViewDate: Date
  onCurrentViewChange: (value: Date) => void
  onCurrentDateChange: (value: Date) => void
}

export const WorkLogCalendar = ({
  workLogMap,
  currentDate,
  currentViewDate,
  onCurrentViewChange,
  onCurrentDateChange,
}: WorkLogCalendarProps): ReactElement => {
  const handleActiveStartDateChange = ({ activeStartDate }: OnArgs): void => {
    if (activeStartDate) {
      onCurrentViewChange(activeStartDate)
    }
  }

  const handleGetTileContent = ({ date }: TileArgs): ReactElement | null => {
    const formattedDate = format(date, WORK_LOG_DATE_FORMAT)
    const debtInMinutes = workLogMap?.get(formattedDate)?.debt

    if (!debtInMinutes || debtInMinutes <= 0) {
      return null
    }

    return (
      <Box bgColor="red.900" px={1} borderRadius={4} mt="auto" mb={2}>
        <Text fontSize="xs">Долг: {getMinutesToTime(debtInMinutes)}</Text>
      </Box>
    )
  }

  return (
    <ReactCalendar
      activeStartDate={currentViewDate}
      value={currentDate}
      showNavigation={false}
      onChange={(value) => onCurrentDateChange(value as unknown as Date)}
      onActiveStartDateChange={handleActiveStartDateChange}
      tileContent={handleGetTileContent}
    />
  )
}
