import { ReactElement, useMemo } from 'react'
import { Center, Table, TableContainer, Tbody, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'

import { ProjectAggregatedReportPreview } from '@/entity/report'
import { getMinutesToTime } from '@/features/work-log'

interface ProjectAggregatedPreviewTableProps {
  data: ProjectAggregatedReportPreview | null
}

interface ProjectPreviewTableRow {
  taskCode: string
  employees: {
    [employeeId in string]: number
  }
}

// TODO: Переместить projectPreviewTableData в Select хука по получению ProjectAggregatedPreviewTable
export const ProjectAggregatedPreviewTable = ({ data }: ProjectAggregatedPreviewTableProps): ReactElement => {
  const projectPreviewTableData = useMemo(
    (): ProjectPreviewTableRow[] | undefined =>
      data?.tasks?.map(
        (task): ProjectPreviewTableRow => ({
          taskCode: task.taskCode,
          employees: data.workLogs
            .filter(({ taskCode }) => task.taskCode === taskCode)
            .reduce(
              (result, currentValue) => ({
                ...result,
                [currentValue.employeeId]: currentValue.durationMinutes,
              }),
              {},
            ),
        }),
      ),
    [data],
  )

  if (!data) {
    return (
      <Center height="100%" width="100%">
        <Text fontSize="lg">Заполни фильтры чтобы получить предпросмотр отчета 👀</Text>
      </Center>
    )
  }

  const calculateEmployeeTotalDuration = (employeeId: string): number => {
    return data.workLogs.reduce((result, currentValue) => {
      if (currentValue.employeeId === employeeId) {
        return result + currentValue.durationMinutes
      }

      return result
    }, 0)
  }

  const calculateEmployeeSalary = (employeeId: string): string => {
    const employeeDuration = calculateEmployeeTotalDuration(employeeId)
    const employeeRate = data.employees.find((employee) => employee.rate)?.rate ?? 0
    const employeeSalary = (employeeDuration / 60) * employeeRate

    return employeeSalary.toFixed(2)
  }

  const totalEmployees = data.employees.length

  return (
    <TableContainer position="absolute" top={0} left={0} maxHeight="100%" overflowY="auto">
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead position="sticky" top={0} bgColor="teal">
          <Tr>
            <Th></Th>
            <Th>Описание работы / роль</Th>
            {data.employees.map(({ projectRole, id }) => (
              <Th key={id}>{projectRole}</Th>
            ))}
          </Tr>
          <Tr>
            <Th></Th>
            <Th>Специалист</Th>
            {data.employees.map(({ name, id }) => (
              <Th key={id}>{name}</Th>
            ))}
          </Tr>
          <Tr>
            <Th></Th>
            <Th>Ставка, тенге</Th>
            {data.employees.map(({ rate, id }) => (
              <Th key={id}>{rate}</Th>
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {projectPreviewTableData?.map((projectPreviewTableRow, index) => (
            <Tr key={index}>
              <Th>{index + 1}</Th>
              <Th>{projectPreviewTableRow.taskCode}</Th>
              {Array.from(Array(totalEmployees).keys()).map((employeeIndex) => {
                const currentEmployeeId = data.employees[employeeIndex].id
                const currentCellEmployeeData = projectPreviewTableRow.employees[currentEmployeeId]

                return (
                  <Th key={currentEmployeeId}>
                    {currentCellEmployeeData ? getMinutesToTime(currentCellEmployeeData) : null}
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Tbody>

        <Tfoot>
          <Tr>
            <Th></Th>
            <Th>Итого, часы</Th>
            {data.employees.map(({ id }) => (
              <Th>{getMinutesToTime(calculateEmployeeTotalDuration(id))}</Th>
            ))}
          </Tr>{' '}
          -
          <Tr>
            <Th></Th>
            <Th>Итого, тенге</Th>
            {data.employees.map(({ id }) => (
              <Th>{calculateEmployeeSalary(id)}</Th>
            ))}
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  )
}
