import { WorkLogType } from '@/entity/work-log'

// TODO: Переместить в feature
export interface ProjectAggregatedReportPreview {
  dateFrom: string
  dateTo: string
  employees: {
    id: string
    name: string
    projectRole: string
    rate: number
  }[]
  periodWorkHours: number
  projectName: string
  tasks: {
    taskCode: string
    workType: WorkLogType
  }[]
  workLogs: {
    durationMinutes: number
    employeeId: string
    taskCode: string
  }[]
}

export const isProjectAggregatedReportPreview = (payload: unknown): payload is ProjectAggregatedReportPreview => {
  const projectAggregatedReportPreview = payload as ProjectAggregatedReportPreview
  return !!projectAggregatedReportPreview.workLogs && !!projectAggregatedReportPreview.tasks
}
