import { ReactElement, useState } from 'react'
import { Box, Button, IconButton, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { AddIcon, EditIcon } from '@chakra-ui/icons'
import { MdAccessTime } from 'react-icons/md'

import { useUserList } from '@/features/user'
import { CreateUserModal } from '@/widgets/create-user-modal'
import { TableContainer } from '@/widgets/table-container'
import { User, USER_ROLE_TITLE } from '@/entity/user'
import { useNavigate } from 'react-router-dom'

export const UsersPage = (): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()

  const userList = useUserList()

  const [userToEdit, setUserToEdit] = useState<User | null>(null)

  const handleUserCalendar = (user: User): void => {
    navigate('/dashboard/calendar', {
      state: {
        user,
      },
    })
  }

  const handleModalOpen = (user: User): void => {
    if (user) {
      setUserToEdit(user)
    }

    onOpen()
  }

  const handleModalClose = (): void => {
    if (userToEdit) {
      setUserToEdit(null)
    }

    onClose()
  }

  const handleModalSubmit = (): void => {
    userList.refetch()
    handleModalClose()
  }

  return (
    <Box display="flex" flexDir="column">
      <Box display="flex" justifyContent="flex-end" pb={4}>
        <Button leftIcon={<AddIcon />} onClick={onOpen}>
          Создать пользователя
        </Button>
      </Box>

      <TableContainer
        isLoading={userList.isLoading || userList.isFetching}
        isEmpty={!userList.data?.length}
        isError={userList.isError}
        onRetry={userList.refetch}
      >
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>ФИО</Th>
              <Th>Email</Th>
              <Th>Роль</Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {userList.data?.map((user) => {
              const userFullName = [user.firstName, user.lastName].join(' ')
              const userRole = USER_ROLE_TITLE[user.role]

              return (
                <Tr key={user.id}>
                  <Td>{userFullName}</Td>
                  <Td>{user.email}</Td>
                  <Td>{userRole}</Td>
                  <Td>
                    <Box display="flex" alignItems="center" justifyContent="flex-end" columnGap={3}>
                      <IconButton aria-label="" onClick={() => handleModalOpen(user)}>
                        <EditIcon />
                      </IconButton>

                      <IconButton aria-label="" onClick={() => handleUserCalendar(user)}>
                        <MdAccessTime />
                      </IconButton>
                    </Box>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <CreateUserModal
        isOpen={isOpen}
        userToEdit={userToEdit}
        onClose={handleModalClose}
        onSuccess={handleModalSubmit}
      />
    </Box>
  )
}
