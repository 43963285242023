import { saveAs } from 'file-saver'

interface downloadFileProps {
  blobParts: Blob
  name: string
  extension: 'xlsx'
}

export const downloadBlobFile = ({ blobParts, name, extension }: downloadFileProps): void => {
  const fileName = [name, extension].join('.')
  const blob = new Blob([blobParts], { type: 'application/octet-stream' })

  saveAs(blob, fileName)
}
