const isObject = (value: unknown): value is object => typeof value === 'object' && value !== null

export const isDeepEqual = (firstValue: unknown, secondValue: unknown): boolean => {
  if (Object.is(firstValue, secondValue)) {
    return true
  }

  if (isObject(firstValue) && isObject(secondValue)) {
    if (Object.keys(firstValue).length !== Object.keys(secondValue).length) {
      return false
    }

    for (const property in firstValue) {
      if (!Object.prototype.hasOwnProperty.call(secondValue, property)) {
        return false
      }

      if (!isDeepEqual(firstValue[property as keyof object], secondValue[property as keyof object])) {
        return false
      }
    }

    return true
  }

  return false
}
