export const getMinutesToTime = (minutes: number = 0): string => {
  if (minutes <= 0) {
    throw new Error('minutes must be a positive number')
  }

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  if (hours === 0) {
    return `${remainingMinutes}м`
  }

  if (remainingMinutes === 0) {
    return `${hours}ч`
  }

  return `${hours}ч ${remainingMinutes}м`
}
