import { ReactElement, useEffect, useId } from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { UseModalProps } from '@chakra-ui/modal'
import { yupResolver } from '@hookform/resolvers/yup'

import { CreateProjectRoleForm, useCreateProjectRole, useUpdateProjectRole } from '@/features/project'
import { ProjectRole } from '@/entity/project'
import { toast } from '@/shared/ui-kit'

import { validationSchema } from '../lib/validation'

interface CreateProjectRoleModalProps extends Pick<UseModalProps, 'isOpen' | 'onClose'> {
  projectRoleToEdit?: ProjectRole | null
  onSuccess: () => void
}

const PROJECT_ROLE_FORM_DEFAULT_VALUES: CreateProjectRoleForm = {
  name: '',
}

export const CreateProjectRoleModal = ({
  isOpen,
  projectRoleToEdit,
  onClose,
  onSuccess,
}: CreateProjectRoleModalProps): ReactElement => {
  const { reset, handleSubmit, register, formState } = useForm<CreateProjectRoleForm>({
    defaultValues: PROJECT_ROLE_FORM_DEFAULT_VALUES,
    resolver: yupResolver<CreateProjectRoleForm>(validationSchema),
  })

  const createProjectRole = useCreateProjectRole()
  const updateProjectRole = useUpdateProjectRole(projectRoleToEdit?.id)

  const roleNameId = useId()

  const isEditMode = !!projectRoleToEdit
  const actionTitle = isEditMode ? 'Редактировать' : 'Создать'

  const handleFormSubmit = (values: CreateProjectRoleForm): void => {
    const mutationHook = isEditMode ? updateProjectRole : createProjectRole
    const toastTitle = isEditMode
      ? `Роль ${values.name} успешно отредактирована`
      : `Роль ${values.name} успешно добавлена`

    mutationHook.mutate(values, {
      onSuccess: () => {
        onSuccess()
        reset(PROJECT_ROLE_FORM_DEFAULT_VALUES)
        toast({
          title: toastTitle,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const handleModalClose = (): void => {
    onClose()
    reset(PROJECT_ROLE_FORM_DEFAULT_VALUES)
  }

  useEffect(() => {
    if (isOpen && projectRoleToEdit) {
      reset(projectRoleToEdit)
    }
  }, [reset, isOpen, projectRoleToEdit])

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />

      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>{actionTitle} роль в проекте</ModalHeader>
          <ModalCloseButton />

          <ModalBody display="flex" flexDir="column" rowGap={3}>
            <FormControl isInvalid={!!formState.errors.name?.message}>
              <FormLabel htmlFor={roleNameId}>Роль</FormLabel>
              <Input id={roleNameId} placeholder="Введите наименование роли" {...register('name')} />

              <FormErrorMessage>{formState.errors.name?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              colorScheme="teal"
              mr={3}
              isDisabled={formState.isSubmitting || !formState.isDirty || !formState.isValid}
              isLoading={createProjectRole.isPending || updateProjectRole.isPending}
            >
              {actionTitle} роль
            </Button>

            <Button variant="outline" isDisabled={!formState.isDirty} onClick={() => reset()}>
              Отменить
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
