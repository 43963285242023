import {
  FaBirthdayCake,
  FaCalendarAlt,
  FaChartPie,
  FaLayerGroup,
  FaNetworkWired,
  FaProjectDiagram,
  FaUsers,
} from 'react-icons/fa'
import { MdDomainVerification } from 'react-icons/md'
import { GoProject } from 'react-icons/go'
import { IconType } from 'react-icons'

import { UserRole } from '@/entity/user'

interface SidebarItem {
  icon: IconType
  label: string
  to: string
  role?: UserRole | UserRole[]
  children?: SidebarItem[]
}

export const SIDEBAR_NAVIGATION_MENU: SidebarItem[] = [
  {
    icon: FaCalendarAlt,
    label: 'Учет времени',
    to: 'calendar',
  },
  {
    icon: MdDomainVerification,
    label: 'Верификация активностей',
    to: 'approvals',
    role: [UserRole.ADMIN, UserRole.MANAGER],
  },
  {
    icon: GoProject,
    label: 'Проекты',
    to: 'projects',
    role: [UserRole.ADMIN, UserRole.MANAGER],
  },
  {
    icon: FaProjectDiagram,
    label: 'Роли в проекте',
    to: 'roles',
    role: [UserRole.ADMIN, UserRole.MANAGER],
  },
  {
    icon: FaNetworkWired,
    label: 'Активности в проекте',
    to: 'project-activity',
    role: [UserRole.ADMIN, UserRole.MANAGER],
  },
  {
    icon: FaUsers,
    label: 'Пользователи',
    to: 'users',
    role: UserRole.ADMIN,
  },
  {
    icon: FaLayerGroup,
    label: 'Департамент',
    to: 'departments',
    role: UserRole.ADMIN,
  },
  {
    icon: FaBirthdayCake,
    label: 'Настройка праздничных дней',
    to: 'holidays',
    role: [UserRole.ADMIN, UserRole.MANAGER],
  },
  {
    icon: FaChartPie,
    label: 'Отчеты по списанным часам',
    to: 'reports',
    role: [UserRole.ADMIN, UserRole.MANAGER],
  },
]
