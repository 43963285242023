import { Department } from '@/entity/department'
import { AvailableProject } from '@/entity/project'
import { IdentifiableWithDisplayName } from '@/shared/model'

import { CreateProjectReportForm } from './create-project-report-form'
import { isDailyReport } from '../utils/is-daily-report'

export interface CreateDailyReportForm extends Pick<CreateProjectReportForm, 'dateFrom' | 'dateTo' | 'reportType'> {
  users: IdentifiableWithDisplayName[] | null
  departments: Department[] | null
  projects: AvailableProject[] | null
}

export const isCreateDailyReportForm = (values: unknown): values is CreateDailyReportForm => {
  const createDailyReportForm = values as CreateDailyReportForm
  return isDailyReport(createDailyReportForm.reportType)
}
