import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { CreateDailyReportForm } from '@/features/report'
import { DailyReportPreview } from '@/entity/report'

import { serializeCreateDailyReport } from '../serializer/serialize-create-daily-report'

export const useCreatePreviewDailyReport = () =>
  useMutation({
    mutationFn: (payload: CreateDailyReportForm) =>
      client
        .post<DailyReportPreview>('/reports/generate/daily/preview', serializeCreateDailyReport(payload))
        .then(({ data }) => data),
  })
