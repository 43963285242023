import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { serializeCreateUser } from '../serializer/serialize-create-user'
import { CreateUserForm } from '../../model/create-user-form'

export const useCreateUser = () =>
  useMutation({
    mutationFn: (payload: CreateUserForm) =>
      client.post<CreateUserForm>('/users', serializeCreateUser(payload)).then(({ data }) => data),
  })
