import { ReactElement, useEffect } from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'
import { UseModalProps } from '@chakra-ui/modal'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'

import { toast } from '@/shared/ui-kit'
import { HolidaySchedule } from '@/entity/holiday'

import { useCreateHoliday } from '../api'
import { CreateHolidayFormSchema } from '../validation/create-holiday-form-schema'
import { CreateHolidayForm } from '../model/create-holiday-form'
import { format } from 'date-fns'

interface CreateHolidayFormModalProps extends Pick<UseModalProps, 'isOpen' | 'onClose'> {
  holidayScheduleToEdit: HolidaySchedule | null
  onSuccess: () => void
}

const EMPTY_HOLIDAY_VALUE = {
  date: '',
}

export const CreateHolidayFormModal = ({
  isOpen,
  holidayScheduleToEdit,
  onClose,
  onSuccess,
}: CreateHolidayFormModalProps): ReactElement => {
  const { setValue, reset, control, formState, handleSubmit } = useForm<CreateHolidayForm>({
    defaultValues: {
      holidays: [EMPTY_HOLIDAY_VALUE],
    },
    resolver: yupResolver<CreateHolidayForm>(CreateHolidayFormSchema),
  })

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'holidays',
  })

  const createHoliday = useCreateHoliday()

  const handleFormSubmit = (values: CreateHolidayForm): void => {
    createHoliday.mutate(values, {
      onSuccess: () => {
        onSuccess()
        reset()
        toast({
          title: 'Праздничные дни были успешно добавлены',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const handleModalClose = (): void => {
    onClose()
    reset()
  }

  useEffect(() => {
    if (isOpen && holidayScheduleToEdit) {
      const serializedData = holidayScheduleToEdit.days.map(({ day, month }) => ({
        date: format(new Date().setMonth(month, day), 'yyyy-MM-dd'),
      }))

      setValue('holidays', serializedData)
    }
  }, [isOpen, holidayScheduleToEdit, setValue])

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />

      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>Добавить праздничные дни</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <VStack spacing={4}>
              {fields.map((field, index) => (
                <FormControl key={field.id} isRequired>
                  <FormLabel>Праздник #{index + 1}</FormLabel>
                  <Flex>
                    <Controller
                      name={`holidays.${index}.date`}
                      control={control}
                      render={({ field }) => <Input {...field} type="date" />}
                    />
                    <IconButton ml={2} aria-label="" onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Flex>
                </FormControl>
              ))}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              colorScheme="teal"
              mr={3}
              leftIcon={<AddIcon />}
              onClick={() => append(EMPTY_HOLIDAY_VALUE)}
            >
              Добавить праздник
            </Button>

            <Button
              type="submit"
              colorScheme="teal"
              mr={3}
              isDisabled={formState.isSubmitting || !formState.isDirty || !formState.isValid}
              isLoading={createHoliday.isPending}
            >
              Сохранить
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
