import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { WorkLogPerDay } from '../../model/work-log-per-day'

export const useDeleteWorkLog = () =>
  useMutation({
    mutationFn: (logWorkId: string) =>
      client.delete<WorkLogPerDay>(`/logs/log-work/${logWorkId}`).then(({ data }) => data),
  })
