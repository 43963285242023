import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { RouterProvider } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'

import theme from './theme'
import router from './router'

import { queryClient } from '@/shared/config/query-client.config'
import { ToastContainer } from '@/shared/ui-kit'

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <RouterProvider router={router} />
      </ChakraProvider>

      <ToastContainer />
    </QueryClientProvider>
  )
}

export default App
