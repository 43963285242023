import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { CreateApprovalForm } from '../../model/create-approval-form'

export const useCreateApproval = () =>
  useMutation({
    mutationFn: (payload: CreateApprovalForm) => client.post('/approvals', payload).then(({ data }) => data),
  })
