import { format } from 'date-fns'

import { WORK_LOG_DATE_FORMAT } from '@/entity/work-log'
import { queryClient } from '@/shared/config/query-client.config'

import { WorkLogPerDay } from '../model/work-log-per-day'
import { WorkLogWithDebt } from '../model/work-log-with-debt'
import { WorkLogPayload } from '../model/work-log-payload'
import { getWorkLogParams } from './get-work-log-params'

export const getCachedWorkLog = (payload: WorkLogPayload): WorkLogPerDay | null => {
  const params = getWorkLogParams(payload)
  const cachedData = queryClient.getQueryData<WorkLogWithDebt>(['work-log', params])

  if (Array.isArray(cachedData?.logWorksPerDay) && cachedData?.logWorksPerDay.length) {
    const formattedDate = format(payload.currentViewDate, WORK_LOG_DATE_FORMAT)
    return cachedData.logWorksPerDay.find((workLog) => workLog.date === formattedDate) ?? null
  }

  return null
}
