import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { CreateProjectActivityForm } from '../../model/create-project-activity-form'

export const useCreateProjectActivity = () =>
  useMutation({
    mutationFn: (payload: CreateProjectActivityForm) =>
      client.post('/typical-activities', payload).then(({ data }) => data),
  })
