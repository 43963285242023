import axios from 'axios'
import { GOOGLE_TOKEN_STORAGE_KEY } from '@/shared/constants'

export const client = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
  withCredentials: true,
})

client.interceptors.request.use((config) => {
  if (localStorage.getItem(GOOGLE_TOKEN_STORAGE_KEY)) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(GOOGLE_TOKEN_STORAGE_KEY)}`
  }

  return config
})

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.data.code === 'TOKEN_INTROSPECT_ERROR') {
      localStorage.removeItem(GOOGLE_TOKEN_STORAGE_KEY)
      window.location.href = '/'
    }

    return Promise.reject(error)
  },
)
