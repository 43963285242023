import { ReactElement } from 'react'
import { Center, Spinner } from '@chakra-ui/react'
import { Navigate, Outlet } from 'react-router-dom'

import { UserRole } from '@/entity/user'
import { useUserRole } from '@/features/user'

interface RequireUserRoleProps {
  role: UserRole | UserRole[]
}

export const RequireUserRole = ({ role }: RequireUserRoleProps): ReactElement => {
  const isArray = Array.isArray(role)
  if (isArray && !role.length) {
    throw new Error('RequireUserRole requires at least 1 role to be executed.')
  }

  const { isLoading, isUserHasRole } = useUserRole()

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  if (isUserHasRole(role)) {
    return <Outlet />
  }

  return <Navigate to="404" />
}
