import { ReactElement } from 'react'
import { Divider, Flex, Heading, IconButton, Text } from '@chakra-ui/react'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'

import { useDeleteWorkLog, WorkLog, WorkLogPerDay } from '@/features/work-log'
import { ApprovalStatus } from '@/entity/approval'
import { GoogleCalendarIcon, toast } from '@/shared/ui-kit'

import { getMinutesToTime } from '../utils/get-minutes-to-time'

interface WorkLogCardProps {
  workLog: WorkLog
  onDelete: (workLogPerDay: WorkLogPerDay) => void
  onEdit: (workLog: WorkLog) => void
}

const CARD_STATUS_BACKGROUND_COLOR: Record<ApprovalStatus, string> = {
  APPROVED: 'teal.900',
  PENDING: 'gray.800',
  REJECTED: 'red.900',
}

const CARD_STATUS_BORDER_COLOR: Record<ApprovalStatus, string> = {
  APPROVED: 'teal.300',
  PENDING: 'gray.700',
  REJECTED: 'red.300',
}

export const WorkLogCard = ({ workLog, onDelete, onEdit }: WorkLogCardProps): ReactElement => {
  const deleteWorkLog = useDeleteWorkLog()

  const getWorkDescription = (): string | undefined => {
    switch (workLog.workType) {
      case 'CUSTOM':
        return `Задача: ${workLog.customWork?.jiraIssue}`

      case 'TYPICAL':
        return `Активность: ${workLog.typicalWork?.name}`

      case 'IMPORT':
        return 'Встреча из Google календаря'

      default:
        return undefined
    }
  }

  const handleWorkLogDelete = (): void => {
    deleteWorkLog.mutate(workLog.id, {
      onSuccess: (workLogPerDay) => {
        onDelete(workLogPerDay)
        toast({
          title: `Запись удалена`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const isMeetingFromCalendar = workLog.workType === 'IMPORT'
  const isApprovalRejected = workLog.approval?.status === 'REJECTED'
  const workDescription = getWorkDescription()

  const borderColor = workLog.approval?.status ? CARD_STATUS_BORDER_COLOR[workLog.approval.status] : undefined
  const backgroundColor = workLog.approval?.status ? CARD_STATUS_BACKGROUND_COLOR[workLog.approval.status] : undefined

  return (
    <Flex
      p={4}
      flexDir="column"
      width="100%"
      boxShadow="md"
      borderRadius={4}
      border="1px solid"
      bgColor={backgroundColor}
      borderColor={borderColor}
    >
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Flex flexDir="column">
          <Heading fontSize="md">
            {workLog.project.name} [{getMinutesToTime(workLog.workDuration)}]
          </Heading>

          <Flex flexDir="column" mt={2} rowGap={2}>
            {workDescription && <Text fontSize="sm">{workDescription}</Text>}
            {workLog.description && <Text fontSize="sm">{workLog.description}</Text>}
          </Flex>
        </Flex>

        {isMeetingFromCalendar ? (
          <GoogleCalendarIcon height="auto" width="40px" />
        ) : (
          <Flex alignItems="center" columnGap={2}>
            <IconButton size="sm" aria-label="" onClick={() => onEdit(workLog)}>
              <EditIcon />
            </IconButton>

            <IconButton size="sm" aria-label="" onClick={handleWorkLogDelete}>
              <DeleteIcon />
            </IconButton>
          </Flex>
        )}
      </Flex>

      {isApprovalRejected && (
        <Flex flexDir="column" mt={2} rowGap={2}>
          <Divider />
          <Text fontSize="sm">Причина отказа: {workLog.approval?.comment ?? '-'}</Text>
        </Flex>
      )}
    </Flex>
  )
}
