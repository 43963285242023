import { PropsWithChildren, ReactElement } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Center,
  Spinner,
  TableContainer as ChakraTableContainer,
  Text,
} from '@chakra-ui/react'

interface TableContainerProps {
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  onRetry: () => void
}

export const TableContainer = ({
  isEmpty,
  isLoading,
  isError,
  onRetry,
  children,
}: PropsWithChildren<TableContainerProps>): ReactElement => {
  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>
          Произошла ошибка при загрузке списка.
          <Button variant="link" onClick={onRetry}>
            Обновить еще раз
          </Button>
        </AlertDescription>
      </Alert>
    )
  }

  if (isEmpty) {
    return (
      <Alert status="warning">
        <AlertIcon />
        <Text>Данные отсутствуют</Text>
      </Alert>
    )
  }

  return <ChakraTableContainer>{children}</ChakraTableContainer>
}
