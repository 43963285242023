import { ReactElement, useEffect, useId } from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { UseModalProps } from '@chakra-ui/modal'
import { useForm } from 'react-hook-form'

import { useCreateDepartment, useUpdateDepartment } from '@/features/department'
import { Department } from '@/entity/department'
import { toast } from '@/shared/ui-kit'

import { CreateDepartmentForm } from '../model/create-department-form'

interface CreateDepartmentFormModalProps extends Pick<UseModalProps, 'isOpen' | 'onClose'> {
  departmentToEdit: Department | null
  onSuccess: () => void
}

const CREATE_DEPARTMENT_FORM_DEFAULT_VALUES: CreateDepartmentForm = {
  name: '',
}

export const CreateDepartmentFormModal = ({
  isOpen,
  departmentToEdit,
  onSuccess,
  onClose,
}: CreateDepartmentFormModalProps): ReactElement => {
  const { reset, register, handleSubmit, formState } = useForm<CreateDepartmentForm>({
    defaultValues: CREATE_DEPARTMENT_FORM_DEFAULT_VALUES,
  })

  const createDepartment = useCreateDepartment()
  const updateDepartment = useUpdateDepartment(departmentToEdit?.id)

  const departmentNameId = useId()

  const isEditMode = !!departmentToEdit
  const isMutationLoading = isEditMode ? updateDepartment.isPending : createDepartment.isPending
  const actionTitle = isEditMode ? 'Редактировать' : 'Создать'

  const handleFormSubmit = (values: CreateDepartmentForm): void => {
    const mutationHook = isEditMode ? updateDepartment : createDepartment
    const toastTitle = isEditMode
      ? `Департамент ${values.name} успешно отредактирован`
      : `Департамент ${values.name} успешно добавлен`

    mutationHook.mutate(values, {
      onSuccess: () => {
        onSuccess()
        reset(CREATE_DEPARTMENT_FORM_DEFAULT_VALUES)
        toast({
          title: toastTitle,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const handleModalClose = (): void => {
    onClose()
    reset(CREATE_DEPARTMENT_FORM_DEFAULT_VALUES)
  }

  useEffect(() => {
    if (isOpen && departmentToEdit) {
      reset({
        name: departmentToEdit?.name,
      })
    }
  }, [isOpen, departmentToEdit, reset])

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>{actionTitle} пользователя</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <FormControl isRequired isInvalid={!!formState.errors.name}>
              <FormLabel htmlFor={departmentNameId}>Департамент</FormLabel>

              <Input id={departmentNameId} placeholder="Введите название департамента" {...register('name')} />

              <FormErrorMessage>{formState.errors.name?.message}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              colorScheme="teal"
              mr={3}
              isDisabled={formState.isSubmitting || !formState.isDirty || !formState.isValid}
              isLoading={isMutationLoading}
            >
              {actionTitle} департамент
            </Button>

            <Button variant="outline" isDisabled={!formState.isDirty} onClick={() => reset()}>
              Очистить
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
