import { CreateDailyReportForm } from '../../model/create-daily-report-form'
import { CreateDailyReport } from '../dto/create-daily-report'

export const serializeCreateDailyReport = (payload: CreateDailyReportForm): CreateDailyReport => ({
  reportTypeCode: payload.reportType?.code ?? '',
  dateFrom: payload.dateFrom,
  dateTo: payload.dateTo,
  usersId: payload.users?.map((user) => user.id) ?? null,
  departmentsId: payload.departments?.map((department) => department.id) ?? null,
  projectsId: payload.projects?.map((project) => project.id) ?? null,
})
