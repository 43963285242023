import { ReactElement, useEffect, useState } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { isToday, setMonth } from 'date-fns'

import {
  DailyWorkLogPanel,
  getCachedWorkLog,
  useWorkLog,
  WorkLog,
  WorkLogCalendar,
  WorkLogCalendarViewingAlert,
  WorkLogCard,
  WorkLogDebtProgressBar,
  WorkLogMonthSlider,
  WorkLogPerDay,
} from '@/features/work-log'
import { CreateWorkLogRecordModal } from '@/widgets/create-work-log-record-modal'
import { User, UserRole } from '@/entity/user'
import { useUserRole } from '@/features/user'

interface CalendarPageState {
  user?: User
}

export const CalendarPage = (): ReactElement => {
  const location = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { isUserHasRole } = useUserRole()

  const [currentDate, setCurrentDate] = useState<Date | null>(null)
  const [currentViewDate, setCurrentViewDate] = useState<Date>(new Date())

  const [workLogToEdit, setWorkLogToEdit] = useState<WorkLog | null>(null)
  const [workLogList, setWorkLogList] = useState<WorkLog[]>([])

  const user = (location.state as CalendarPageState)?.user

  const workLog = useWorkLog({
    currentViewDate,
    userId: user?.id,
  })

  const isFutureDate = currentDate ? currentDate > new Date() : false
  const isTodayDate = currentDate ? isToday(currentDate) : false
  const isTodayButtonDisabled = isTodayDate && currentViewDate.getMonth() === currentDate?.getMonth()
  const isUserCalendarViewingEnabled = !!user && isUserHasRole(UserRole.ADMIN)

  const handleCurrentDateChange = (date: Date): void => {
    const cachedWorkLog = getCachedWorkLog({
      currentViewDate: date,
      userId: user?.id,
    })

    handleWorkLogChange(cachedWorkLog)
    setCurrentDate(date)
  }

  const handleTodayButtonClick = (): void => {
    const currentDate = new Date()

    handleMonthChange(currentDate.getMonth())
    handleCurrentDateChange(currentDate)
  }

  const handleMonthChange = (monthNumber: number): void => {
    if (currentViewDate.getMonth() !== monthNumber) {
      setCurrentViewDate(setMonth(currentViewDate, monthNumber))
    }
  }

  const handleWorkLogEdit = (workLog: WorkLog): void => {
    setWorkLogToEdit(workLog)
    onOpen()
  }

  const handleWorkLogDelete = (workLogPerDay: WorkLogPerDay): void => {
    handleWorkLogChange(workLogPerDay)
    workLog.refetch()
  }

  const handleWorkLogChange = (workLogPerDay: WorkLogPerDay | null): void => {
    setWorkLogList(workLogPerDay?.logs ?? [])
  }

  const handleModalSubmit = (workLogPerDay: WorkLogPerDay): void => {
    handleModalClose()
    handleWorkLogChange(workLogPerDay)
    workLog.refetch()
  }

  const handleModalClose = (): void => {
    if (workLogToEdit) {
      setWorkLogToEdit(null)
    }

    onClose()
  }

  useEffect(() => {
    if (!!workLog.data?.hashmap.size && !currentDate) {
      handleCurrentDateChange(new Date())
    }
    // eslint-disable-next-line
  }, [currentDate, workLog.data])

  return (
    <Box height="100%" display="flex" flexDir="column" rowGap={4} pr={8}>
      {isUserCalendarViewingEnabled && (
        <Box>
          <WorkLogCalendarViewingAlert user={user} />
        </Box>
      )}

      <Flex flexDir="column" rowGap={4} height="80px">
        <Box display="flex" alignItems="center" columnGap={4}>
          <WorkLogMonthSlider currentMonth={currentViewDate.getMonth()} onMonthChange={handleMonthChange} />

          <Button
            size="sm"
            variant="outline"
            px={4}
            isDisabled={isTodayButtonDisabled}
            onClick={handleTodayButtonClick}
          >
            Сегодня
          </Button>
        </Box>

        <WorkLogDebtProgressBar
          isLoading={workLog.isLoading}
          totalDebt={workLog.data?.totalDebt}
          workLogMap={workLog.data?.hashmap}
        />
      </Flex>

      <Box flex={1} display="flex" alignItems="stretch" columnGap={8} maxHeight="calc(100% - 112px)">
        <Box flex={3}>
          <WorkLogCalendar
            workLogMap={workLog.data?.hashmap}
            currentDate={currentDate}
            currentViewDate={currentViewDate}
            onCurrentViewChange={setCurrentViewDate}
            onCurrentDateChange={handleCurrentDateChange}
          />
        </Box>

        <Box flex={1} height="100%">
          {!isFutureDate && (
            <DailyWorkLogPanel
              options={workLogList}
              currentDate={currentDate}
              onAddActivity={onOpen}
              renderOption={(workLog) => (
                <WorkLogCard
                  key={workLog.id}
                  workLog={workLog}
                  onEdit={handleWorkLogEdit}
                  onDelete={handleWorkLogDelete}
                />
              )}
            />
          )}
        </Box>

        <CreateWorkLogRecordModal
          isOpen={isOpen}
          userId={user?.id}
          currentDate={currentDate}
          workLogToEdit={workLogToEdit}
          onSubmit={handleModalSubmit}
          onClose={handleModalClose}
        />
      </Box>
    </Box>
  )
}
