import { ForwardedRef, forwardRef, ReactElement, useRef, useTransition } from 'react'
import { Box, Checkbox, SystemStyleObject, useMultiStyleConfig } from '@chakra-ui/react'
import {
  ActionMeta,
  chakraComponents,
  InputActionMeta,
  OnChangeValue,
  OptionProps,
  Select as ChakraSelect,
  SelectInstance,
  Size,
} from 'chakra-react-select'
import type { GroupBase } from 'react-select'

import { SelectProps } from '../model/select-props'
import { getOptionKeys } from '../utils/get-option-keys'
import { useSize } from '../utils/get-size'

const SELECT_ALL_VALUE = '*'
const SELECT_ALL_LABEL = 'Выбрать все'

export const SelectRoot = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: SelectProps<Option, IsMulti, Group>,
  ref: ForwardedRef<SelectInstance<Option, IsMulti, Group>>,
): ReactElement => {
  const isAllSelected = useRef<boolean>(false)
  const [isPending, startTransition] = useTransition()

  const noOptionsMessage = ({ inputValue }: { inputValue: string }): null | string => {
    if (inputValue.trim().length === 0) {
      return null
    }

    return props.noOptionsText ?? 'Данные отсутствуют'
  }

  const handleInputChange = (inputText: string, meta: InputActionMeta): void => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
      startTransition(() => {
        props.onInputChange?.(inputText, meta)
      })
    }
  }

  if (props.isAllSelect) {
    const { valueKey, labelKey } = getOptionKeys(props)

    const allOption = {
      [valueKey]: SELECT_ALL_VALUE,
      [labelKey]: SELECT_ALL_LABEL,
    } as Option

    const options = [allOption, ...(props.options as readonly (Option | Group)[])]

    const handleChange = (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>): void => {
      const selectedOption = actionMeta.option
      if (selectedOption?.[valueKey as keyof Option] === SELECT_ALL_VALUE) {
        if (isAllSelected.current) {
          props.onChange?.([] as OnChangeValue<Option, IsMulti>, actionMeta)
          isAllSelected.current = false
          return
        }

        isAllSelected.current = true
        props.onChange?.(props.options as OnChangeValue<Option, IsMulti>, actionMeta)
        return
      }

      if (Array.isArray(newValue) && options?.length) {
        const isAllCheckedExceptFirstOne =
          options.length - newValue.length === 1 && !newValue.find((value) => value[valueKey] === SELECT_ALL_VALUE)

        if (isAllCheckedExceptFirstOne) {
          isAllSelected.current = true
          props.onChange?.(props.options as OnChangeValue<Option, IsMulti>, actionMeta)
          return
        }
      }

      if (isAllSelected.current) {
        isAllSelected.current = false
      }

      props.onChange?.(newValue, actionMeta)
    }

    const Option = (props: OptionProps<Option, IsMulti, Group>) => {
      const menuItemStyles = useMultiStyleConfig('Menu').item

      const size: Size = useSize(props.selectProps.size)
      const horizontalPaddingOptions = {
        sm: '0.6rem',
        md: '0.8rem',
        lg: '1rem',
      }
      const verticalPaddingOptions = {
        sm: '0.3rem',
        md: '0.4rem',
        lg: '0.5rem',
      }

      const initialSx: SystemStyleObject = {
        ...menuItemStyles,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        textAlign: 'start',
        fontSize: size,
        paddingX: horizontalPaddingOptions[size],
        paddingY: verticalPaddingOptions[size],
        _selected: {
          bg: 'transparent',
          color: 'white',
        },
      }

      return (
        <Box
          {...props.innerProps}
          sx={initialSx}
          ref={props.innerRef}
          data-focus={props.isFocused ? true : undefined}
          aria-disabled={props.isDisabled ? true : undefined}
          aria-selected={props.isSelected}
        >
          <Checkbox
            key={props.label}
            isIndeterminate={(props as { value?: unknown })?.value === SELECT_ALL_VALUE && isAllSelected.current}
            isChecked={props.isSelected || isAllSelected.current}
          >
            {props.label}
          </Checkbox>
        </Box>
      )
    }

    return (
      <ChakraSelect
        {...props}
        ref={ref}
        components={{
          ...chakraComponents,
          Option: Option,
        }}
        isMulti={true as IsMulti}
        options={options}
        menuPlacement={props.menuPlacement ?? 'auto'}
        closeMenuOnSelect={false}
        tabSelectsValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        onChange={handleChange}
      />
    )
  }

  return (
    <ChakraSelect
      {...props}
      ref={ref}
      isLoading={isPending || props.isLoading}
      noOptionsMessage={noOptionsMessage}
      onInputChange={handleInputChange}
    />
  )
}

export const Select = forwardRef(SelectRoot) as <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: SelectProps<Option, IsMulti, Group>,
) => ReturnType<typeof SelectRoot>
