import { ReactElement, useState } from 'react'
import { Box, Button, IconButton, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'

import { CreateDepartmentFormModal, DeleteDepartmentFormModal, useDepartmentList } from '@/features/department'
import { TableContainer } from '@/widgets/table-container'
import { Department } from '@/entity/department'
import { UseDisclosureReturn } from '@chakra-ui/hooks'

export const DepartmentPage = (): ReactElement => {
  const createDepartmentModal = useDisclosure()
  const deleteDepartmentModal = useDisclosure()

  const departmentList = useDepartmentList()

  const [selectedDepartment, setSelectedDepartment] = useState<Department | null>(null)

  const handleModalOpen = (modal: UseDisclosureReturn, department: Department): void => {
    setSelectedDepartment(department)
    modal.onOpen()
  }

  const handleModalClose = (modal: UseDisclosureReturn): void => {
    if (selectedDepartment) {
      setSelectedDepartment(null)
    }

    modal.onClose()
  }

  const handleModalSubmit = (modal: UseDisclosureReturn): void => {
    departmentList.refetch()
    handleModalClose(modal)
  }

  return (
    <Box display="flex" flexDir="column">
      <Box display="flex" justifyContent="flex-end" pb={4}>
        <Button leftIcon={<AddIcon />} onClick={createDepartmentModal.onOpen}>
          Создать департамент
        </Button>
      </Box>

      <TableContainer
        isLoading={departmentList.isLoading || departmentList.isFetching}
        isError={departmentList.isError}
        isEmpty={!departmentList.data?.length}
        onRetry={departmentList.refetch}
      >
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Название департамента</Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {departmentList.data?.map((department) => (
              <Tr key={department.id}>
                <Td>{department.name}</Td>
                <Td>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" columnGap={3}>
                    <IconButton aria-label="" onClick={() => handleModalOpen(createDepartmentModal, department)}>
                      <EditIcon />
                    </IconButton>

                    <IconButton aria-label="" onClick={() => handleModalOpen(deleteDepartmentModal, department)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <CreateDepartmentFormModal
        isOpen={createDepartmentModal.isOpen}
        departmentToEdit={selectedDepartment}
        onClose={() => handleModalClose(createDepartmentModal)}
        onSuccess={() => handleModalSubmit(createDepartmentModal)}
      />

      <DeleteDepartmentFormModal
        isOpen={deleteDepartmentModal.isOpen}
        departmentToDelete={selectedDepartment}
        onClose={() => handleModalClose(deleteDepartmentModal)}
        onSuccess={() => handleModalSubmit(deleteDepartmentModal)}
      />
    </Box>
  )
}
