import yup from '@/shared/config/yup.config'

export const CreateHolidayFormSchema = yup.object({
  holidays: yup
    .array()
    .of(
      yup.object().shape({
        date: yup.string().required(),
      }),
    )
    .required()
    .min(1, 'Введите как минимум 1 праздник'),
})
