import { mixed } from 'yup'

import { FeedbackType } from '@/entity/feedback'
import { convertMegaBytesToBytes } from '@/shared/utils'
import yup from '@/shared/config/yup.config'

const MAX_SIZE_IN_MB = 20

export const createUserFeedbackFormSchema = yup.object({
  type: yup.mixed<FeedbackType>().oneOf(['BUG_REPORT', 'FEATURE_REQUEST']).required(),
  comment: yup.string().required(),
  attachment: mixed<File>().test({
    message: `Файл слишком большого размера - Максимальное величина файла - ${MAX_SIZE_IN_MB}МБ`,
    test: (value) => {
      if (value instanceof File && value.size) {
        return value.size <= convertMegaBytesToBytes(MAX_SIZE_IN_MB)
      }

      return true
    },
  }),
})
