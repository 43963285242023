import { useQuery } from '@tanstack/react-query'

import { HolidayInfo } from '@/entity/holiday'
import { client } from '@/shared/config/axios.config'

import { serializeCreateHolidayInfo } from '../serializer/serialize-create-holiday-info'

export const useHolidayList = (params: { year: string; month?: number }) =>
  useQuery({
    queryKey: ['holiday', 'list', params],
    queryFn: () => client.get<HolidayInfo[]>('/dictionaries/holidays', { params }).then(({ data }) => data),
    select: (data: HolidayInfo[]) => serializeCreateHolidayInfo(data),
  })
