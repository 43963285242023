import yup from '@/shared/config/yup.config'

export const validationSchema = yup.object({
  user: yup
    .object({
      id: yup.string().required(),
      displayName: yup.string().required(),
    })
    .required(),
  projectRoleName: yup
    .object({
      id: yup.string().required(),
      name: yup.string().required(),
    })
    .required(),
  rate: yup
    .number()
    .default(null)
    .when('$isRateRequired', ([isRateRequired], schema) =>
      isRateRequired
        ? schema
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required()
            .min(1, 'Введите минимальный рейт')
        : schema.notRequired(),
    ),
})
