import { getDateFrom } from './get-date-from'
import { getDateTo } from './get-date-to'
import { WorkLogParams } from '../model/work-log-params'
import { WorkLogPayload } from '../model/work-log-payload'

export const getWorkLogParams = (payload: WorkLogPayload): WorkLogParams => ({
  userId: payload.userId,
  projectId: payload.projectId,
  dateFrom: getDateFrom(payload.currentViewDate),
  dateTo: getDateTo(payload.currentViewDate),
})
