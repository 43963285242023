import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { CreateProjectReportForm } from '../../model/create-project-report-form'
import { serializeCreateProjectReport } from '../serializer/serialize-create-project-report'

export const useCreateProjectReport = () =>
  useMutation({
    mutationFn: (payload: CreateProjectReportForm) =>
      client
        .post<Blob>('/reports/generate/project', serializeCreateProjectReport(payload), {
          responseType: 'blob',
        })
        .then(({ data }) => data),
  })
