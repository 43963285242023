import { ReactNode } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { useIsAuthorized } from '../hooks/use-is-authorized'

export const RequireAuth = (): ReactNode => {
  const isAuthorized = useIsAuthorized()
  if (!isAuthorized) {
    return <Navigate to="/" />
  }

  return <Outlet />
}
