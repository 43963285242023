import { ReactElement, useId } from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react'
import { UseModalProps } from '@chakra-ui/modal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { CreateRejectCommentForm } from '../model/create-reject-comment-form'
import { rejectCommentModalSchema } from '../lib/validation/reject-comment-modal-schema'

interface ApprovalCommentModalProps extends Pick<UseModalProps, 'isOpen' | 'onClose'> {
  onSubmit: (payload: CreateRejectCommentForm) => void
}

export const RejectCommentModal = ({ isOpen, onClose, onSubmit }: ApprovalCommentModalProps): ReactElement => {
  const { reset, formState, register, handleSubmit } = useForm<CreateRejectCommentForm>({
    defaultValues: {
      comment: '',
    },
    resolver: yupResolver<CreateRejectCommentForm>(rejectCommentModalSchema),
  })

  const commentId = useId()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Причина отказа</ModalHeader>
          <ModalCloseButton />

          <ModalBody display="flex" flexDir="column" rowGap={3}>
            <FormControl isRequired isInvalid={!!formState.errors.comment?.message}>
              <FormLabel htmlFor={commentId}>Описание</FormLabel>
              <Textarea
                id={commentId}
                placeholder="Введите причину отказа"
                minHeight="150px"
                {...register('comment')}
              />

              <FormErrorMessage>{formState.errors.comment?.message}</FormErrorMessage>
            </FormControl>

            <ModalFooter>
              <Button
                type="submit"
                colorScheme="teal"
                mr={3}
                isDisabled={formState.isSubmitting || !formState.isDirty || !formState.isValid}
              >
                Отправить
              </Button>

              <Button variant="outline" isDisabled={!formState.isDirty} onClick={() => reset()}>
                Отменить
              </Button>
            </ModalFooter>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  )
}
