import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { WorkLogPerDay } from '../../model/work-log-per-day'
import { CreateWorkLogRecordForm } from '../../model/create-work-log-record-form'
import { serializeCreateWorkLog } from '../serializer/serialize-create-work-log'

interface UpdateWorkLogRequestParams {
  userId?: string
}

const getRequestParams = (payload: CreateWorkLogRecordForm): UpdateWorkLogRequestParams => {
  const requestParams = {} as UpdateWorkLogRequestParams
  if (payload.userId) {
    requestParams.userId = payload.userId
  }

  return requestParams
}

export const useUpdateWorkLog = (logWorkId?: string) =>
  useMutation({
    mutationFn: (payload: CreateWorkLogRecordForm) => {
      const body = serializeCreateWorkLog(payload)
      const params = getRequestParams(payload)

      return client.put<WorkLogPerDay>(`/logs/log-work/${logWorkId}`, body, { params }).then(({ data }) => data)
    },
  })
