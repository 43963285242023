import { ReactElement, useEffect, useId, useState } from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Select as ChakraSelect,
  Checkbox,
  FormHelperText,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { UseModalProps } from '@chakra-ui/modal'
import { yupResolver } from '@hookform/resolvers/yup'

import { useFilteredUserList } from '@/features/user'
import { CreateProjectForm, useCreateProject, useProjectActivityList, useUpdateProject } from '@/features/project'
import { useDepartmentList } from '@/features/department'
import { Project } from '@/entity/project'
import { toast, Select } from '@/shared/ui-kit'

import { validationSchema } from '../lib/validation'

interface CreateProjectModalProps extends Pick<UseModalProps, 'isOpen' | 'onClose'> {
  projectToEdit: Project | null
  onSuccess: () => void
}

const EXCLUDED_PROJECT_KEYS: Set<keyof Project> = new Set(['id', 'typicalActivities', 'isArchived'])
const PROJECT_MODAL_FORM_DEFAULT_VALUES: CreateProjectForm = {
  name: '',
  type: 'COMMERCIAL',
  department: null,
  managers: [],
  typicalActivities: [],
  isNormalizedHours: true,
}

export const CreateProjectModal = ({
  isOpen,
  projectToEdit,
  onSuccess,
  onClose,
}: CreateProjectModalProps): ReactElement => {
  const { reset, control, handleSubmit, register, formState, watch } = useForm<CreateProjectForm>({
    defaultValues: PROJECT_MODAL_FORM_DEFAULT_VALUES,
    resolver: yupResolver<CreateProjectForm>(validationSchema),
  })

  const projectTypeValue = watch('type')
  const isProjectCommercial = projectTypeValue === 'COMMERCIAL'

  const projectNameId = useId()
  const projectTypeId = useId()
  const projectManagersId = useId()
  const projectDepartmentId = useId()
  const projectActivityId = useId()

  const [inputValue, setInputValue] = useState<string>('')

  const createProject = useCreateProject()
  const updateProject = useUpdateProject(projectToEdit?.id)

  const departmentList = useDepartmentList()
  const projectActivityList = useProjectActivityList()
  const filteredUserList = useFilteredUserList({
    name: inputValue,
    role: ['ADMIN', 'MANAGER'],
  })

  const isEditMode = !!projectToEdit
  const actionTitle = isEditMode ? 'Редактировать' : 'Создать'

  const handleInputChange = (inputText: string) => {
    setInputValue(inputText)
  }

  const onSubmit = (values: CreateProjectForm): void => {
    const mutationHook = isEditMode ? updateProject : createProject
    const toastTitle = isEditMode
      ? `Проект ${values.name} успешно отредактирован`
      : `Проект ${values.name} успешно добавлен`

    mutationHook.mutate(values, {
      onSuccess: () => {
        onSuccess()
        reset(PROJECT_MODAL_FORM_DEFAULT_VALUES)
        toast({
          title: toastTitle,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const handleModalClose = (): void => {
    onClose()
    reset(PROJECT_MODAL_FORM_DEFAULT_VALUES)
  }

  useEffect(() => {
    const isReadyToEdit = isOpen && projectActivityList.data && !!projectToEdit
    if (isReadyToEdit) {
      const typicalActivityNames = new Set(projectToEdit.typicalActivities.map(({ name }) => name))
      const typicalActivities = projectActivityList.data?.filter(({ name }) => typicalActivityNames.has(name))

      const projectFormKeys = Object.entries(projectToEdit).reduce((projectKeys, [key, value]) => {
        if (!EXCLUDED_PROJECT_KEYS.has(key as keyof Project)) {
          projectKeys[key as keyof CreateProjectForm] = value
        }

        return projectKeys
      }, {} as Partial<CreateProjectForm>)

      // Бек возвращает только те данные которые были заполнены ранее, поэтому для нормальной работоспособности формы
      // нужно еще и положить PROJECT_MODAL_FORM_DEFAULT_VALUES, чтобы предотвратить undefined значения в форме
      reset({
        ...PROJECT_MODAL_FORM_DEFAULT_VALUES,
        ...projectFormKeys,
        typicalActivities,
      })
    }
  }, [isOpen, projectActivityList.data, projectToEdit, reset])

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />

      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>{actionTitle} проект</ModalHeader>
          <ModalCloseButton />

          <ModalBody display="flex" flexDir="column" rowGap={3}>
            <FormControl isRequired isInvalid={!!formState.errors.name?.message}>
              <FormLabel htmlFor={projectNameId}>Проект</FormLabel>
              <Input id={projectNameId} placeholder="Введите наименование проекта" {...register('name')} />

              <FormErrorMessage>{formState.errors.name?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!formState.errors.type?.message}>
              <FormLabel htmlFor={projectTypeId}>Тип проекта</FormLabel>
              <ChakraSelect id={projectTypeId} {...register('type')}>
                <option value="COMMERCIAL">Коммерческий</option>
                <option value="NONCOMMERCIAL">Некоммерческий</option>
                <option value="INVEST">Инвестиционный</option>
              </ChakraSelect>

              <FormErrorMessage>{formState.errors.type?.message}</FormErrorMessage>
            </FormControl>

            {!isProjectCommercial && (
              <FormControl isInvalid={!!formState.errors.department?.message}>
                <Skeleton isLoaded={!departmentList.isLoading}>
                  <FormLabel htmlFor={projectDepartmentId}>Департамент</FormLabel>
                </Skeleton>

                <Skeleton isLoaded={!departmentList.isLoading}>
                  <Controller
                    control={control}
                    name="department"
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputId={projectDepartmentId}
                        isClearable
                        placeholder="Выберите департамент..."
                        noOptionsText="Данный департамент отсутствует"
                        loadingMessage={() => 'Загрузка...'}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        isLoading={departmentList.isLoading}
                        options={departmentList.data}
                      />
                    )}
                  />
                </Skeleton>

                <FormErrorMessage>{formState.errors.department?.message}</FormErrorMessage>
              </FormControl>
            )}

            <FormControl isRequired isInvalid={!!formState.errors.managers?.message}>
              <FormLabel htmlFor={projectManagersId}>Менеджеры</FormLabel>

              <Controller
                control={control}
                name="managers"
                render={({ field }) => (
                  <Select
                    {...field}
                    inputId={projectManagersId}
                    isMulti
                    isClearable
                    filterOption={null}
                    placeholder="Введите имя менеджера..."
                    noOptionsText="Данный менеджер отсутствует"
                    loadingMessage={() => 'Загрузка...'}
                    inputValue={inputValue}
                    isLoading={filteredUserList.isLoading}
                    options={filteredUserList.data}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.displayName}
                    onInputChange={handleInputChange}
                  />
                )}
              />

              <FormErrorMessage>{formState.errors.managers?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formState.errors.typicalActivities}>
              <Skeleton isLoaded={!projectActivityList.isLoading}>
                <FormLabel htmlFor={projectActivityId}>Активность</FormLabel>
              </Skeleton>

              <Skeleton isLoaded={!projectActivityList.isLoading}>
                <Controller
                  control={control}
                  name="typicalActivities"
                  render={({ field }) => (
                    <Select
                      {...field}
                      inputId={projectActivityId}
                      isMulti
                      isClearable
                      filterOption={null}
                      placeholder="Выберите активность..."
                      loadingMessage={() => 'Загрузка...'}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isLoading={projectActivityList.isLoading}
                      options={projectActivityList.data}
                    />
                  )}
                />
              </Skeleton>

              <FormErrorMessage>{formState.errors.typicalActivities?.message}</FormErrorMessage>
            </FormControl>

            <FormControl>
              {!isProjectCommercial && (
                <Checkbox colorScheme="teal" {...register('isNormalizedHours')}>
                  Приводить ли часы
                </Checkbox>
              )}

              {isProjectCommercial && (
                <FormHelperText>В коммерческих проектах логика приведенных часов всегда включена</FormHelperText>
              )}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              colorScheme="teal"
              mr={3}
              isDisabled={formState.isSubmitting || !formState.isValid || !formState.isDirty}
              isLoading={updateProject.isPending || createProject.isPending}
            >
              {actionTitle}
            </Button>

            <Button variant="outline" isDisabled={!formState.isDirty} onClick={() => reset()}>
              Очистить
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
