import { useQuery } from '@tanstack/react-query'

import { Department } from '@/entity/department'
import { client } from '@/shared/config/axios.config'

export const useDepartmentList = () =>
  useQuery({
    queryKey: ['department', 'list'],
    queryFn: () => client.get<Department[]>('/departments').then(({ data }) => data),
  })
