import { ReactElement } from 'react'
import { Box, Center, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'

import { DailyReportPreview } from '@/entity/report'

interface DailyReportPreviewTableProps {
  data: DailyReportPreview | null
}

export const DailyReportPreviewTable = ({ data }: DailyReportPreviewTableProps): ReactElement => {
  if (!data) {
    return (
      <Center height="100%" width="100%">
        <Text fontSize="lg">Заполни фильтры чтобы получить предпросмотр отчета 👀</Text>
      </Center>
    )
  }

  return (
    <TableContainer position="absolute" top={0} left={0} width="100%" maxHeight="100%" overflowY="auto">
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead position="sticky" top={0} bgColor="teal">
          <Tr>
            <Th>Отдел</Th>
            <Th>Сотрудник</Th>
            <Th>Почта</Th>
            <Th>Дата</Th>
            <Th>Часы</Th>
            <Th width="300px">Описание</Th>
            <Th>Номер задачи в Jira</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.tasks.map((reportPreviewTask, index) => (
            <Tr key={index}>
              <Td>{reportPreviewTask.departmentName}</Td>
              <Td>{reportPreviewTask.employeeName}</Td>
              <Td>{reportPreviewTask.employeeEmail}</Td>
              <Td>{reportPreviewTask.date}</Td>
              <Td>{reportPreviewTask.workHours}</Td>
              <Td>
                <Box as="div" maxWidth="300px">
                  <Text style={{ textWrap: 'wrap' }}>{reportPreviewTask.description}</Text>
                </Box>
              </Td>
              <Td>{reportPreviewTask.jiraIssue}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
