import { useMutation } from '@tanstack/react-query'
import { client } from '@/shared/config/axios.config'

import { CreateFeedbackForm } from '../../model/create-feedback-form'

const getFeedbackBody = (attachment?: File): FormData | undefined => {
  if (!attachment) {
    return undefined
  }

  const formData = new FormData()
  formData.append('attachment', attachment)

  return formData
}

export const useCreateFeedback = () =>
  useMutation({
    mutationFn: ({ type, comment, attachment }: CreateFeedbackForm) =>
      client
        .postForm('/feedback', getFeedbackBody(attachment), {
          params: {
            type,
            comment,
          },
        })
        .then(({ data }) => data),
  })
