import { useQuery } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { IdentifiableWithDisplayName } from '@/shared/model'

export const useFilteredUserList = (params: { name: string; workLogRequired?: boolean; role?: string[] }) =>
  useQuery({
    enabled: !!params.name.trim().length,
    queryKey: ['user', 'list', params],
    queryFn: () =>
      client
        .get<IdentifiableWithDisplayName[]>('/users/simple-view', {
          params,
          paramsSerializer: {
            indexes: null,
          },
        })
        .then(({ data }) => data),
  })
