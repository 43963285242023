import { ReactElement } from 'react'
import { AbsoluteCenter, Box, Button, Divider, Heading, Link, Text } from '@chakra-ui/react'
import { Navigate } from 'react-router-dom'

import { useGoogleUrl, useIsAuthorized } from '@/features/auth'
import { GoogleIcon, TekmatesIcon } from '@/shared/ui-kit'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const AuthPage = (): ReactElement => {
  const googleUrl = useGoogleUrl()
  const isAuthorized = useIsAuthorized()

  const handleGoogleAuthClick = (): void => {
    googleUrl.mutate(undefined, {
      onSuccess: (response) => {
        window.location.href = response.signInUrl
      },
    })
  }

  if (isAuthorized) {
    return <Navigate to="dashboard" />
  }

  return (
    <Box position="relative" height="100vh" width="100%">
      <Box display="flex" justifyContent="center" py={4}>
        <TekmatesIcon height="auto" width="236px" />
      </Box>

      <AbsoluteCenter zIndex="20">
        <Box display="flex" flexDir="column" borderRadius={4} py={12} px={16} boxShadow="md" maxWidth="560">
          <Heading size="md">Вход в систему</Heading>
          <Button mt={4} leftIcon={<GoogleIcon />} isLoading={googleUrl.isPending} onClick={handleGoogleAuthClick}>
            <Text fontSize="sm" fontWeight="400">
              Вход через Google
            </Text>
          </Button>

          <Divider mt={8} />

          <Text mt={8} fontSize="sm">
            Если у вас не получается авторизоваться в <b>Chronos</b> через Google почту, то обязательно пишите в{' '}
            <Link isExternal href="https://tekmates.slack.com/team/U06QEMLSFFF" color="teal.300">
              Slack <ExternalLinkIcon mx="2px" />
            </Link>
          </Text>
        </Box>
      </AbsoluteCenter>
    </Box>
  )
}
