import { ReactElement } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import { Sidebar } from '@/widgets/sidebar'

export const HomePage = (): ReactElement => (
  <Flex h="100vh" position="relative">
    <Sidebar />
    <Box as="main" marginLeft="80px" flex="1" p="4" height="100vh">
      <Outlet />
    </Box>
  </Flex>
)
