import { ReactElement, useState } from 'react'
import { Box, Button, IconButton, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { UseDisclosureReturn } from '@chakra-ui/hooks'

import { DeleteProjectRoleModal, useProjectRoleList } from '@/features/project'
import { CreateProjectRoleModal } from '@/widgets/create-project-role-modal'
import { ProjectRole } from '@/entity/project'
import { TableContainer } from '@/widgets/table-container'

export const ProjectRolePage = (): ReactElement => {
  const addProjectRoleModal = useDisclosure()
  const deleteProjectRoleModal = useDisclosure()

  const projectRoleList = useProjectRoleList()

  const [selectedProjectRole, setSelectedProjectRole] = useState<ProjectRole | null>(null)

  const handleModalOpen = (modal: UseDisclosureReturn, projectRole: ProjectRole): void => {
    setSelectedProjectRole(projectRole)
    modal.onOpen()
  }

  const handleModalClose = (modal: UseDisclosureReturn): void => {
    if (selectedProjectRole) {
      setSelectedProjectRole(null)
    }

    modal.onClose()
  }

  const handleModalSubmit = (modal: UseDisclosureReturn): void => {
    projectRoleList.refetch()
    handleModalClose(modal)
  }

  return (
    <Box display="flex" flexDir="column">
      <Box display="flex" justifyContent="flex-end" pb={4}>
        <Button leftIcon={<AddIcon />} onClick={addProjectRoleModal.onOpen}>
          Создать роль в проекте
        </Button>
      </Box>

      <TableContainer
        isLoading={projectRoleList.isLoading || projectRoleList.isFetching}
        isEmpty={!projectRoleList.data?.length}
        isError={projectRoleList.isError}
        onRetry={projectRoleList.refetch}
      >
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Название роли</Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {projectRoleList.data?.map((projectRole) => (
              <Tr key={projectRole.id}>
                <Td>{projectRole.name}</Td>
                <Td>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" columnGap={3}>
                    <IconButton aria-label="" onClick={() => handleModalOpen(addProjectRoleModal, projectRole)}>
                      <EditIcon />
                    </IconButton>

                    <IconButton aria-label="" onClick={() => handleModalOpen(deleteProjectRoleModal, projectRole)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <CreateProjectRoleModal
          isOpen={addProjectRoleModal.isOpen}
          projectRoleToEdit={selectedProjectRole}
          onSuccess={() => handleModalSubmit(addProjectRoleModal)}
          onClose={() => handleModalClose(addProjectRoleModal)}
        />

        <DeleteProjectRoleModal
          isOpen={deleteProjectRoleModal.isOpen}
          projectRoleToDelete={selectedProjectRole}
          onSuccess={() => handleModalSubmit(deleteProjectRoleModal)}
          onClose={() => handleModalClose(deleteProjectRoleModal)}
        />
      </TableContainer>
    </Box>
  )
}
