import { ReactElement, useMemo } from 'react'
import { Box, Progress, Skeleton, Text } from '@chakra-ui/react'
import { isWeekend } from 'date-fns'

import { getMinutesToTime, MAX_MINUTES_IN_DAY, WorkLogPerDay } from '@/features/work-log'

interface WorkLogDebtProgressBarProps {
  isLoading: boolean
  totalDebt?: number
  workLogMap?: Map<string, WorkLogPerDay>
}

const getProgressBarValue = (leftValue: number, totalValue: number): number =>
  totalValue === 0 ? 0 : ((totalValue - leftValue) / totalValue) * 100

export const WorkLogDebtProgressBar = ({
  isLoading,
  workLogMap,
  totalDebt = 0,
}: WorkLogDebtProgressBarProps): ReactElement => {
  const totalAmount = useMemo((): number => {
    if (!workLogMap?.size) {
      return 0
    }

    const options = Array.from(workLogMap.values())
    return options.reduce((result, workLog) => (isWeekend(workLog.date) ? result : result + MAX_MINUTES_IN_DAY), 0)
  }, [workLogMap])

  const isFutureMonth = totalAmount === 0
  const totalAmountOfFilledHours = getProgressBarValue(totalDebt, totalAmount)

  return (
    <Box display="flex" flexDir="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Skeleton isLoaded={!isLoading}>
          <Text fontSize="xs" fontWeight="600">
            Заполнено: {totalAmountOfFilledHours.toFixed(2)}%
          </Text>
        </Skeleton>

        {!isFutureMonth && (
          <Skeleton isLoaded={!isLoading}>
            <Text fontSize="xs" fontWeight="600">
              {totalDebt > 0 ? `Осталось списать: ${getMinutesToTime(totalDebt)}` : 'Все часы списаны! 😍'}
            </Text>
          </Skeleton>
        )}
      </Box>

      <Progress
        mt={1}
        value={isLoading ? 0 : totalAmountOfFilledHours}
        size="xs"
        colorScheme="teal"
        sx={{
          '> div': {
            transition: '0.3s ease width',
          },
        }}
      />
    </Box>
  )
}
