import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { CreateDepartmentForm } from '../../model/create-department-form'

export const useCreateDepartment = () =>
  useMutation({
    mutationFn: (payload: CreateDepartmentForm) => client.post('/departments', payload).then(({ data }) => data),
  })
