import { useQuery } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { AvailableProject } from '@/entity/project'

export const useAvailableProjectList = () =>
  useQuery({
    queryKey: ['project', 'available', 'list'],
    queryFn: () => client.get<AvailableProject[]>('/projects/available').then(({ data }) => data),
  })
