import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftIcon } from '@chakra-ui/icons'
import { Button, Heading, Text, VStack } from '@chakra-ui/react'

export const Error404Page = (): ReactElement => {
  const navigate = useNavigate()

  return (
    <VStack justify="center" gap="4" as="section" mt={['20', null, '40']} textAlign="center">
      <Heading>404 | Страница не найдена</Heading>
      <Text fontSize={{ md: 'xl' }}>Ты перешел на страницу которая не существует 😢</Text>

      <Button colorScheme="teal" size="md" mt={4} leftIcon={<ArrowLeftIcon />} onClick={() => navigate('/dashboard')}>
        Назад на главную
      </Button>
    </VStack>
  )
}
