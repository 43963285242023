import { useQuery } from '@tanstack/react-query'

import { ProjectMember } from '@/entity/project'
import { client } from '@/shared/config/axios.config'

export const useProjectMemberList = (projectId?: string) =>
  useQuery({
    enabled: !!projectId,
    queryKey: ['projects', 'list', 'members', projectId],
    queryFn: () => client.get<ProjectMember[]>(`/projects/${projectId}/members`).then(({ data }) => data),
  })
