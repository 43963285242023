import { HolidayInfo, HolidaySchedule } from '@/entity/holiday'

export const serializeCreateHolidayInfo = (payload: HolidayInfo[]): HolidaySchedule[] => {
  const hashmap = new Map<HolidaySchedule['year'], HolidaySchedule['days']>()

  payload.forEach((holiday) => {
    const value = { day: holiday.day, month: holiday.month }
    const existingHolidays = hashmap.get(holiday.year) ?? []

    hashmap.set(holiday.year, [...existingHolidays, value])
  })

  return Array.from(hashmap.entries()).map(([year, days]) => ({
    year,
    days,
  }))
}
