// TODO:
// 1. Попробовать использовать Indexed Access Types
// 2. После первого шага - переместить в правильное место по FSD архитектуре

export interface ProjectDetailedReportPreview {
  projectName: string
  dateFrom: string
  dateTo: string
  employees: {
    id: string
    name: string
    projectRole: string
    rate: number
  }[]
  periodWorkHours: number
  customWorkLogs: {
    employeeId: string
    durationMinutes: number
    jiraIssue: string
    taskDescription: string
  }[]
  typicalWorkLogs: {
    employeeId: string
    durationMinutes: number
    taskCode: string
  }[]
  importWorkLogs: {
    employeeId: string
    durationMinutes: number
    description: string
  }[]
}

export const isProjectDetailedReportPreview = (payload: unknown): payload is ProjectDetailedReportPreview => {
  const projectAggregatedReportPreview = payload as ProjectDetailedReportPreview
  return (
    !!projectAggregatedReportPreview.customWorkLogs &&
    !!projectAggregatedReportPreview.importWorkLogs &&
    !!projectAggregatedReportPreview.typicalWorkLogs
  )
}
