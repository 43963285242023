import { QueryClient, MutationCache, QueryCache } from '@tanstack/react-query'
import { AxiosError, AxiosResponse, isAxiosError } from 'axios'

import { toast } from '@/shared/ui-kit'

interface ChronosError {
  code: string
  description: string
  timestamp: string
}

interface BlobResponseError {
  text: () => Promise<string>
}

const isBlobResponseError = (
  response: AxiosResponse<ChronosError>,
): response is AxiosResponse<ChronosError & BlobResponseError> =>
  response.request.responseType === 'blob' && 'text' in response.data

const getErrorMessage = async (error: AxiosError<ChronosError>): Promise<string | null> => {
  switch (error.response?.status) {
    case 412: {
      if (!isBlobResponseError(error.response)) {
        return error.response?.data?.description as string
      }

      try {
        const errorJson = await error.response.data.text()
        const errorObject = JSON.parse(errorJson) as ChronosError

        return errorObject.description
      } catch (error) {
        return 'Ошибка сервера! Пожалуйста повторите позже'
      }
    }

    case 500:
      return 'Ошибка сервера! Пожалуйста повторите позже'

    default:
      return null
  }
}

const globalErrorHandler = async (error: unknown): Promise<void> => {
  if (isAxiosError(error)) {
    const errorMessage = await getErrorMessage(error)

    if (errorMessage) {
      toast({
        title: 'Ошибка!',
        description: errorMessage,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => globalErrorHandler(error),
  }),
  mutationCache: new MutationCache({
    onError: (error) => globalErrorHandler(error),
  }),
})
