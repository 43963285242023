import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'
import { AddProjectMemberForm } from '@/features/project'

import { serializeUpdateProjectMember } from '../serializer/serialize-update-project-member'

export const useUpdateProjectMember = (projectId?: string) =>
  useMutation({
    mutationFn: (payload: AddProjectMemberForm) =>
      client
        .put(`/projects/${projectId}/members/${payload.user?.id}`, serializeUpdateProjectMember(payload))
        .then(({ data }) => data),
  })
