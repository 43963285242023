import { WorkTimeSlot } from '@/entity/work-log'
import { MAX_MINUTES_IN_DAY } from '@/features/work-log'

const TIME_STEP = 15

const formatTime = (hour: number, minute: number): string => {
  const formattedHour = hour.toString().padStart(2, '0')
  const formattedNumber = minute.toString().padStart(2, '0')

  return `${formattedHour}:${formattedNumber}`
}

export const getWorkTimeSlots = (time: number = MAX_MINUTES_IN_DAY): WorkTimeSlot[] => {
  const maxAvailableTime = time > MAX_MINUTES_IN_DAY ? MAX_MINUTES_IN_DAY : time

  const totalTimeSlots: WorkTimeSlot[] = []
  const totalSlotsInMinutes = Math.floor(maxAvailableTime / TIME_STEP)

  let hours = 0
  let minutes = 0

  for (let i = 0; i <= totalSlotsInMinutes; i++) {
    totalTimeSlots.push({
      label: formatTime(hours, minutes),
      value: hours * 60 + minutes,
    })

    minutes += TIME_STEP
    if (minutes === 60) {
      hours++
      minutes = 0
    }
  }

  totalTimeSlots.shift()
  return totalTimeSlots
}
