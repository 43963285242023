import { ReactElement, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Box, Button, IconButton, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { AddIcon, ArrowLeftIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { UseDisclosureReturn } from '@chakra-ui/hooks'

import { AddProjectMemberModal } from '@/widgets/add-project-member-modal'
import { TableContainer } from '@/widgets/table-container'
import { DeleteProjectMemberModal, useProjectList, useProjectMemberList } from '@/features/project'
import { ProjectMember } from '@/entity/project'

export const ProjectMembersPage = (): ReactElement => {
  const { projectId } = useParams()
  const navigate = useNavigate()

  const addProjectMemberModal = useDisclosure()
  const removeProjectMemberModal = useDisclosure()

  const projectList = useProjectList()
  const projectMemberList = useProjectMemberList(projectId)

  const [selectedProjectMember, setSelectedProjectMember] = useState<ProjectMember | null>(null)

  const isRateRequired = projectList.data?.find((project) => project.id === projectId)?.type === 'COMMERCIAL'

  const handleModalOpen = (modal: UseDisclosureReturn, projectMember: ProjectMember): void => {
    if (projectMember) {
      setSelectedProjectMember(projectMember)
    }

    modal.onOpen()
  }

  const handleModalClose = (modal: UseDisclosureReturn): void => {
    if (selectedProjectMember) {
      setSelectedProjectMember(null)
    }

    modal.onClose()
  }

  const handleModalSubmit = (modal: UseDisclosureReturn): void => {
    projectMemberList.refetch()
    handleModalClose(modal)
  }

  if (!projectId) {
    return <Navigate to="/dashboard/projects" />
  }

  return (
    <Box display="flex" flexDir="column">
      <Box display="flex" justifyContent="space-between" pb={4}>
        <Button variant="ghost" leftIcon={<ArrowLeftIcon />} onClick={() => navigate('/dashboard/projects')}>
          Назад
        </Button>

        <Button leftIcon={<AddIcon />} onClick={addProjectMemberModal.onOpen}>
          Добавить пользователя в проект
        </Button>
      </Box>

      <TableContainer
        isLoading={projectMemberList.isLoading || projectMemberList.isFetching}
        isEmpty={!projectMemberList.data?.length}
        isError={projectMemberList.isError}
        onRetry={projectMemberList.refetch}
      >
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Пользователь</Th>
              <Th>Роль в проекте</Th>
              {isRateRequired && <Th>Рейт</Th>}
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {projectMemberList.data?.map((project) => (
              <Tr key={project.user.id}>
                <Td>{project.user.displayName}</Td>
                <Td>{project.projectRoleName}</Td>
                {isRateRequired && <Td>{project.rate}</Td>}
                <Td>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" columnGap={3}>
                    <IconButton aria-label="" onClick={() => handleModalOpen(addProjectMemberModal, project)}>
                      <EditIcon />
                    </IconButton>

                    <IconButton aria-label="" onClick={() => handleModalOpen(removeProjectMemberModal, project)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <AddProjectMemberModal
        isOpen={addProjectMemberModal.isOpen}
        isRateRequired={isRateRequired}
        projectMemberToEdit={selectedProjectMember}
        onSuccess={() => handleModalSubmit(addProjectMemberModal)}
        onClose={() => handleModalClose(addProjectMemberModal)}
      />

      <DeleteProjectMemberModal
        isOpen={removeProjectMemberModal.isOpen}
        projectMemberToDelete={selectedProjectMember}
        onSuccess={() => handleModalSubmit(removeProjectMemberModal)}
        onClose={() => handleModalClose(removeProjectMemberModal)}
      />
    </Box>
  )
}
