import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { WorkLogPerDay } from '../../model/work-log-per-day'
import { CreateWorkLogRecordForm } from '../../model/create-work-log-record-form'
import { serializeCreateWorkLog } from '../serializer/serialize-create-work-log'

interface CreateWorkLogRequestParams {
  userId?: string
}

const getRequestParams = (payload: CreateWorkLogRecordForm): CreateWorkLogRequestParams => {
  const requestParams = {} as CreateWorkLogRequestParams
  if (payload.userId) {
    requestParams.userId = payload.userId
  }

  return requestParams
}

export const useCreateWorkLog = () =>
  useMutation({
    mutationFn: (payload: CreateWorkLogRecordForm) => {
      const body = serializeCreateWorkLog(payload)
      const params = getRequestParams(payload)

      return client.post<WorkLogPerDay>('/logs/log-work', body, { params }).then(({ data }) => data)
    },
  })
