import { isDailyReport, isProjectReport } from '@/features/report'
import { ReportType } from '@/entity/report'
import yup from '@/shared/config/yup.config'
import { ProjectType } from '@/entity/project'

export const createProjectReportSchema = yup.object().shape({
  reportType: yup
    .object()
    .shape({
      code: yup.mixed<ReportType['code']>().oneOf(['PROJECT_AGGREGATE', 'PROJECT_DETAILED', 'DAILY']).required(),
      name: yup.string().required(),
    })
    .required(),
  dateFrom: yup.string().required(),
  dateTo: yup
    .string()
    .required()
    .when('dateFrom', ([dateFrom], schema) => {
      const dateFromValue = dateFrom as unknown as string

      return schema.test({
        test: (dateToValue) => new Date(dateToValue) > new Date(dateFromValue),
        message: '"Дата до" должна быть после "даты от"',
      })
    }),
  project: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
      type: yup.mixed<ProjectType>().oneOf(['COMMERCIAL', 'NONCOMMERCIAL', 'INVEST']).required(),
      typicalActivities: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.string().required(),
            name: yup.string().required(),
            withDescription: yup.boolean().required(),
          }),
        )
        .required(),
    })
    .when('reportType', ([reportType], schema) => {
      const reportTypeValue = reportType as unknown as ReportType

      return isProjectReport(reportTypeValue) ? schema.required() : schema.nullable()
    }),
  projects: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
        type: yup.mixed<ProjectType>().oneOf(['COMMERCIAL', 'NONCOMMERCIAL', 'INVEST']).required(),
        typicalActivities: yup
          .array()
          .of(
            yup.object().shape({
              id: yup.string().required(),
              name: yup.string().required(),
              withDescription: yup.boolean().required(),
            }),
          )
          .required(),
      }),
    )
    .default(null)
    .when('reportType', ([reportType], schema) => {
      const reportTypeValue = reportType as unknown as ReportType

      return isDailyReport(reportTypeValue) ? schema.min(1, 'Выберите как минимум один проект') : schema.nullable()
    }),
  users: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        displayName: yup.string().required(),
      }),
    )
    .default(null)
    .nullable(),
  departments: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
      }),
    )
    .default(null)
    .nullable(),
})
