import { useMutation } from '@tanstack/react-query'

import { client } from '@/shared/config/axios.config'

import { CreateProjectForm } from '../../model/create-project-form'
import { serializeCreateProject } from '../serializer/serialize-create-project'

export const useUpdateProject = (projectId?: string) =>
  useMutation({
    mutationFn: (payload: CreateProjectForm) =>
      client.put(`/projects/${projectId}`, serializeCreateProject(payload)).then(({ data }) => data),
  })
