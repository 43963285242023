import { createIcon } from '@chakra-ui/icons'

export const GoogleCalendarIcon = createIcon({
  displayName: 'GoogleCalendarIcon',
  viewBox: '0 0 141.7 141.7',
  path: (
    <>
      <path fill="#fff" d="M95.8,45.9H45.9V95.8H95.8Z"></path>
      <path fill="#34a853" d="M95.8,95.8H45.9v22.5H95.8Z"></path>
      <path fill="#4285f4" d="M95.8,23.4H30.9a7.55462,7.55462,0,0,0-7.5,7.5V95.8H45.9V45.9H95.8Z"></path>
      <path fill="#188038" d="M23.4,95.8v15a7.55462,7.55462,0,0,0,7.5,7.5h15V95.8Z"></path>
      <path fill="#fbbc04" d="M118.3,45.9H95.8V95.8h22.5Z"></path>
      <path fill="#1967d2" d="M118.3,45.9v-15a7.55462,7.55462,0,0,0-7.5-7.5h-15V45.9Z"></path>
      <path fill="#ea4335" d="M95.8,118.3l22.5-22.5H95.8Z"></path>
      <polygon
        fill="#2a83f8"
        points="77.916 66.381 75.53 63.003 84.021 56.868 87.243 56.868 87.243 85.747 82.626 85.747 82.626 62.772 77.916 66.381"
      ></polygon>
      <path
        fill="#2a83f8"
        d="M67.29834,70.55785A7.88946,7.88946,0,0,0,70.78,64.12535c0-4.49-4-8.12-8.94-8.12a8.77525,8.77525,0,0,0-8.74548,6.45379l3.96252,1.58258a4.41779,4.41779,0,0,1,4.473-3.51635,4.138,4.138,0,1,1,.06256,8.24426v.00513h-.0559l-.00666.00061-.00964-.00061H59.15v3.87677h2.70642L61.88,72.65a4.70514,4.70514,0,1,1,0,9.37,5.35782,5.35782,0,0,1-3.96588-1.69354,4.59717,4.59717,0,0,1-.80408-1.2442l-.69757-1.69946L52.23005,79c.62,4.33,4.69,7.68,9.61,7.68,5.36,0,9.7-3.96,9.7-8.83A8.63346,8.63346,0,0,0,67.29834,70.55785Z"
      ></path>
    </>
  ),
})
