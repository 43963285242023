import { ReactElement, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  ListItem,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { AddIcon, EditIcon } from '@chakra-ui/icons'
import { format } from 'date-fns'

import { TableContainer } from '@/widgets/table-container'
import { CreateHolidayFormModal, useHolidayList } from '@/features/holiday'
import { HolidaySchedule } from '@/entity/holiday'

const DEFAULT_CURRENT_YEAR = new Date().getFullYear().toString()

const getFormatDate = (day: number, month: number): string => {
  const currentDate = new Date().setMonth(month, day)
  return format(currentDate, 'dd MMMM')
}

export const HolidayPage = (): ReactElement => {
  const createHolidayModal = useDisclosure()

  const holidayScheduleList = useHolidayList({
    year: DEFAULT_CURRENT_YEAR,
  })

  const [holidayScheduleToEdit, setHolidaySchedule] = useState<HolidaySchedule | null>(null)

  const handleModalSuccess = (): void => {
    holidayScheduleList.refetch()
    createHolidayModal.onClose()
  }

  const handleModalOpen = (holidaySchedule: HolidaySchedule): void => {
    setHolidaySchedule(holidaySchedule)
    createHolidayModal.onOpen()
  }

  const handleModalClose = (): void => {
    if (holidayScheduleToEdit) {
      setHolidaySchedule(null)
    }

    createHolidayModal.onClose()
  }

  return (
    <Box display="flex" flexDir="column">
      <Box display="flex" justifyContent="flex-end" pb={4}>
        <Button leftIcon={<AddIcon />} onClick={createHolidayModal.onOpen}>
          Создать праздник
        </Button>
      </Box>

      <TableContainer
        isLoading={holidayScheduleList.isLoading || holidayScheduleList.isFetching}
        isError={holidayScheduleList.isError}
        isEmpty={!holidayScheduleList.data?.length}
        onRetry={holidayScheduleList.refetch}
      >
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Даты праздников</Th>
              <Th>Год</Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {holidayScheduleList.data?.map((holidaySchedule) => (
              <Tr key={holidaySchedule.year}>
                <Td>
                  <UnorderedList>
                    {holidaySchedule.days.map(({ day, month }) => (
                      <ListItem mt={2}>{getFormatDate(day, month)}</ListItem>
                    ))}
                  </UnorderedList>
                </Td>
                <Td>{holidaySchedule.year}</Td>
                <Td>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" columnGap={3}>
                    <IconButton aria-label="" onClick={() => handleModalOpen(holidaySchedule)}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <CreateHolidayFormModal
        isOpen={createHolidayModal.isOpen}
        holidayScheduleToEdit={holidayScheduleToEdit}
        onSuccess={handleModalSuccess}
        onClose={handleModalClose}
      />
    </Box>
  )
}
