import { ReactElement, useEffect, useId, useState } from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Skeleton,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { Navigate, useParams } from 'react-router-dom'
import { UseModalProps } from '@chakra-ui/modal'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  AddProjectMemberForm,
  useAddProjectMember,
  useProjectRoleList,
  useUpdateProjectMember,
} from '@/features/project'
import { useFilteredUserList } from '@/features/user'
import { ProjectMember } from '@/entity/project'
import { toast, Select } from '@/shared/ui-kit'

import { validationSchema } from '../lib/validation'

interface AddProjectMemberProps extends Pick<UseModalProps, 'isOpen' | 'onClose'> {
  projectMemberToEdit: ProjectMember | null
  isRateRequired: boolean
  onSuccess: () => void
}

const RATE_STEP = 1_000
const RATE_DEFAULT_VALUE = 0
const DEFAULT_VALUES = {
  user: null,
  projectRoleName: null,
  rate: null,
}

export const AddProjectMemberModal = ({
  isOpen,
  isRateRequired,
  projectMemberToEdit,
  onSuccess,
  onClose,
}: AddProjectMemberProps): ReactElement => {
  const { projectId } = useParams()

  const { reset, control, handleSubmit, formState } = useForm<AddProjectMemberForm>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver<AddProjectMemberForm>(validationSchema),
    context: {
      isRateRequired,
    },
  })

  const projectMemberId = useId()
  const projectRoleId = useId()
  const projectRateId = useId()

  const [inputValue, setInputValue] = useState<string>('')

  const addProjectMember = useAddProjectMember(projectId)
  const updateProjectMember = useUpdateProjectMember(projectId)

  const projectRoleList = useProjectRoleList()
  const filteredUserList = useFilteredUserList({
    name: inputValue,
  })

  const isEditMode = !!projectMemberToEdit
  const actionTitle = isEditMode ? 'Редактировать' : 'Добавить'

  const handleInputChange = (inputText: string): void => {
    setInputValue(inputText)
  }

  const handleFormSubmit = (values: AddProjectMemberForm): void => {
    const mutationHook = isEditMode ? updateProjectMember : addProjectMember
    const successTitle = isEditMode
      ? `Данные пользователя ${values.user?.displayName} отредактированы`
      : `Пользователь ${values.user?.displayName} добавлен в проект`

    mutationHook.mutate(values, {
      onSuccess: () => {
        onSuccess()
        reset(DEFAULT_VALUES)

        toast({
          title: successTitle,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const handleModalClose = (): void => {
    onClose()
    reset(DEFAULT_VALUES)
  }

  useEffect(() => {
    const isReadyToEdit = isOpen && projectRoleList.data?.length && projectMemberToEdit
    if (isReadyToEdit) {
      const roleToEdit = projectRoleList.data?.find(({ name }) => name === projectMemberToEdit.projectRoleName)

      reset({
        ...projectMemberToEdit,
        projectRoleName: roleToEdit,
      })
    }
  }, [isOpen, projectRoleList.data, projectMemberToEdit, reset])

  if (!projectId) {
    return <Navigate to="/dashboard/projects" />
  }

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>{actionTitle} пользователя в проект</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDir="column" rowGap={3}>
            <FormControl isRequired isInvalid={!!formState.errors.user}>
              <FormLabel htmlFor={projectMemberId}>Пользователь</FormLabel>

              <Controller
                control={control}
                name="user"
                render={({ field }) => (
                  <Select
                    {...field}
                    inputId={projectMemberId}
                    isClearable
                    filterOption={null}
                    placeholder="Введите имя сотрудника..."
                    noOptionsText="Данный сотрудник отсутствует"
                    loadingMessage={() => 'Загрузка...'}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.displayName}
                    inputValue={inputValue}
                    isDisabled={isEditMode}
                    isLoading={filteredUserList.isLoading}
                    options={filteredUserList.data}
                    onInputChange={handleInputChange}
                  />
                )}
              />

              <FormErrorMessage>{formState.errors.user?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!formState.errors.user}>
              <FormLabel htmlFor={projectMemberId}>Роль в проекте</FormLabel>

              <Skeleton isLoaded={!projectRoleList.isLoading}>
                <Controller
                  control={control}
                  name="projectRoleName"
                  render={({ field }) => (
                    <Select
                      {...field}
                      inputId={projectRoleId}
                      isClearable
                      placeholder="Выберите роль..."
                      noOptionsText="Данная роль отсутствует"
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isLoading={projectRoleList.isLoading}
                      options={projectRoleList.data}
                    />
                  )}
                />
              </Skeleton>

              <FormErrorMessage>{formState.errors.projectRoleName?.message}</FormErrorMessage>
            </FormControl>

            {isRateRequired && (
              <FormControl isInvalid={!!formState.errors.rate}>
                <FormLabel htmlFor={projectRateId}>Рейт</FormLabel>

                <Controller
                  control={control}
                  name="rate"
                  render={({ field }) => (
                    <NumberInput
                      step={RATE_STEP}
                      defaultValue={RATE_DEFAULT_VALUE}
                      keepWithinRange
                      min={0}
                      value={field.value ?? 0}
                      onChange={(value) => field.onChange(value ? parseInt(value) : 0)}
                    >
                      <NumberInputField id={projectRateId} placeholder="Введите роль в проекте" />

                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                />

                <FormHelperText>Можно управлять стрелками вверх и них, шаг - {RATE_STEP}</FormHelperText>

                <FormErrorMessage>{formState.errors.rate?.message}</FormErrorMessage>
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              colorScheme="teal"
              mr={3}
              isDisabled={formState.isSubmitting || !formState.isValid || !formState.isDirty}
              isLoading={addProjectMember.isPending || updateProjectMember.isPending}
            >
              {actionTitle}
            </Button>

            <Button variant="outline" isDisabled={!formState.isDirty} onClick={() => reset()}>
              Очистить
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
