import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

import {
  ApprovalPage,
  AuthPage,
  AuthRedirectPage,
  CalendarPage,
  DepartmentPage,
  Error404Page,
  HolidayPage,
  HomePage,
  ProjectActivityPage,
  ProjectMembersPage,
  ProjectPage,
  ProjectRolePage,
  ReportsPage,
  UsersPage,
} from '@/pages'
import { RequireAuth } from '@/features/auth'
import { RequireUserRole } from '@/features/user'
import { UserRole } from '@/entity/user'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<AuthPage />} />
      <Route path="auth/redirect" element={<AuthRedirectPage />} />

      <Route element={<RequireAuth />}>
        <Route path="dashboard" element={<HomePage />}>
          <Route path="" element={<Navigate to="calendar" />} />
          <Route path="calendar" element={<CalendarPage />} />

          <Route element={<RequireUserRole role={UserRole.ADMIN} />}>
            <Route path="users" element={<UsersPage />} />
          </Route>

          <Route element={<RequireUserRole role={[UserRole.ADMIN, UserRole.MANAGER]} />}>
            <Route path="approvals" element={<ApprovalPage />} />
            <Route path="roles" element={<ProjectRolePage />} />

            <Route path="projects" element={<ProjectPage />} />
            <Route path="projects/:projectId" element={<ProjectMembersPage />} />

            <Route path="holidays" element={<HolidayPage />} />
            <Route path="project-activity" element={<ProjectActivityPage />} />
            <Route path="departments" element={<DepartmentPage />} />
            <Route path="reports" element={<ReportsPage />} />
          </Route>

          <Route path="*" element={<Error404Page />} />
        </Route>
      </Route>
    </>,
  ),
)

export default router
